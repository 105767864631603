import { HTMLAttributes } from 'react';
import { Container } from './styles';
import { transformMaskCurrency } from '../../../utils/transformMaskCurrency';
import { useTitleAndUser } from '../../../hooks/userTitle';
import logo from '../../../assets/home/patrimonio.svg';
import { useActives } from '../../../hooks/useActives';

interface IPropsCardHomeData extends HTMLAttributes<HTMLDivElement> {
  title: string;
  value: number | string;
  image?: string;
  isVideo?: boolean;
  isReceived?: boolean;
  diff?: number;
}

export function CardHomeData({
  title,
  value,
  image,
  isVideo = false,
  diff = 0,
  ...props
}: IPropsCardHomeData) {
  const { showActive } = useActives() as any;

  //@ts-ignore
  const { permissions } = useTitleAndUser();

  const isAdmin = permissions.includes('Admin');
  const isPaidUser =
    permissions.includes('General') || permissions.includes('IRPF');
  const isFreeUser =
    permissions.includes('Grátis') || permissions.includes('Trial');
  const isBlocked = !isAdmin && !isPaidUser && isFreeUser;

  const maskValue = !isVideo && transformMaskCurrency(Number(value));
  const canViewData = showActive && (isAdmin || isPaidUser);

  return (
    <Container
      isBlocked={isBlocked}
      {...props}
      title={
        isBlocked
          ? 'Você precisa ter uma assinatura para visualizar os dados'
          : undefined
      }
    >
      <span
        style={{
          fontSize: '14px', 
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
          maxWidth: 'calc(100% - 40px)',  
        }}
      >
        {title}
      </span>
      <img
        className="imagem"
        src={image || logo}
        alt={title}
        style={{ width: '20px', height: '20px' }}
      />
      {isVideo ? (
        <h1 style={{ color: '#40444C' }}>
          {value !== '-% ' ? <>{value}</> : '-'}
        </h1>
      ) : (
        <h1 className={isBlocked ? 'blocked' : ''}>
          {canViewData ? maskValue : 'R$ *******'}
        </h1>
      )}
    </Container>
  );
}
