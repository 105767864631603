import axios from 'axios';
import { useEffect, useState } from 'react';
import FiiGrid from './FIIGrid.js';
import { InfinityScroll } from '../../../components/InfinityScroll';
import { useTitleAndUser } from '../../../hooks/userTitle';
import { financeRoutes } from '../../../utils/RoutesApi';
import {
  ContentFiltersAndSearchFIIs,
  ContentList,
  RowDiv,
  WarningNewMessage,
} from './acoesPainelStyles';
import './styles.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { AsideFiltersFii } from './components/AsideFiltersFii';
import { InputComponent } from '../../../components/InputComponent';
import { SideModal } from '../../../components/SideModal';
import { Funnel, Info } from '@phosphor-icons/react';
import { CardFiiRankMobile } from './components/CardFiiRankMobile';
import { validateParamsFiiSearch } from './utils/validateParamsFiiSearch.ts';
import { ListCardsActivesRank } from '../LoadingModels';


const baseURL = process.env.REACT_APP_API_URL;

const FiiPainel = ({
  seg = null,
  isComponent = false,
  onSelectedActive = ({ slug, type }) => {},
}) => {
  // const getFilterFiiPainel = localStorage.getItem('filterFiiPainel-v0.0.2');
  const { setTitle } = useTitleAndUser();
  const [modalFilter, setModalFilter] = useState(false);
  const [values, setValues] = useState({
    count: 0,
    current_page: 0,
    data: [],
    links: { next: null, previous: null },
    total_pages: 0,
  });
  const [loading, setLoading] = useState(true);
  const [params, setParams] = useState(() => {
    // if (getFilterFiiPainel && !isComponent) {
    //   return JSON.parse(getFilterFiiPainel);
    // }
    return {
      dy_range: 0,
      nome_administrador: '',
      setor: '',
      tipo_gestao: '',
      qtde_ativos_range: 0,
      valor_patrimonial_range: 0,
      patrimonio_liquido_range: 0,
      p_vp_range: 0,
      vacancia_range: 0,
      nome_ou_codigo: '',
      tipofii: '',
    };
  });
  const [newLoading, setNewLoading] = useState(false);

  useEffect(() => {
    if (!isComponent) {
      setTitle('Lista de FIIs');
    }
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      getActivesWithNotes();
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [params]);

  async function handleUpdateScreenScrollInfinity(nextPage) {
    setNewLoading(true);

    try {
      const response = await axios.get(
        `${baseURL}${financeRoutes.FINANCE_LIST_FIIS_WITH_RANK}`,
        { params: nextPage }
      );

      setValues((state) => {
        return {
          ...response.data,
          data: [...state.data, ...response.data.data],
        };
      });
      setNewLoading(false);

      setLoading(false);
    } catch (error) {
      setNewLoading(false);
    }
  }
  async function getActivesWithNotes() {
    setLoading(true);

    try {
      const newParams = validateParamsFiiSearch({
        ...params,
      });
      const response = await axios.get(
        `${baseURL}${financeRoutes.FINANCE_LIST_FIIS_WITH_RANK}`,
        { params: newParams }
      );

      setValues(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  return (

      <ContentList>
        <div style={{flexDirection: 'row',}}>
        {window.screen.width > 1100 && (
          <AsideFiltersFii
            onSelectedFilters={(state) => setParams((params) => (params = state))}
            selectedFilters={params}
          />
        )}
        <div style={{ background: 'white', padding: '1rem', width: '100%' }}>
          {!isComponent && (
            <>
              <div style={{  
                // marginTop: '-7%',
                marginLeft: '2rem',
                }}
                >
                <ContentFiltersAndSearchFIIs>
                  <InputComponent
                    roundedFull
                    isSearch
                    placeholder="Buscar fundo"
                    value={params.nome_ou_codigo}
                    onChange={(e) =>
                      setParams({
                        ...params,
                        nome_ou_codigo: e.target.value,
                      })
                    }
                  />
                  {window.screen.width < 1100 && (
                    <button
                      className="buttonFilter"
                      onClick={() => setModalFilter(true)}
                    >
                      <Funnel size={36} color="#505050" />
                    </button>
                  )}
                </ContentFiltersAndSearchFIIs>
              </div>
              <br />
              <WarningNewMessage>
                <Info color="#EC6643" size={24} />
                <p>
                  O Ranking tem como objetivo orientar você a iniciar seus estudos
                  pelos ativos mais relevantes. No entanto, é importante ressaltar
                  que ele não deve ser interpretado como uma recomendação
                  específica de compra ou venda.
                </p>
              </WarningNewMessage>
            </>
          )}
          {loading ? (
            <>
              <RowDiv>
                <ListCardsActivesRank />
                <ListCardsActivesRank />
              </RowDiv>
              <br />
              <RowDiv>
                <ListCardsActivesRank />
                <ListCardsActivesRank />
              </RowDiv>
            </>
          ) : (
            <RowDiv>
              {values.data.map((assets, index) =>
                window.screen.width > 1100 ? (
                  <FiiGrid
                    key={index}
                    asset={assets}
                    isComponent={isComponent}
                    onSelectedActive={onSelectedActive}
                  />
                ) : (
                  <CardFiiRankMobile
                    key={index}
                    fii={assets}
                    isComponent={isComponent}
                    selectedActive={onSelectedActive}
                  />
                )
              )}
            </RowDiv>
          )}
          {values.data.length < values.count && (
            <div>
              <h3>Carregando...</h3>
            </div>
          )}
          {values.data.length === values.count && loading !== true && (
            <div>
              <h3>Você chegou ao final da lista</h3>
            </div>
          )}
          {!loading && !newLoading && values.data.length < values.count && (
            <InfinityScroll
              fetchMore={() =>
                handleUpdateScreenScrollInfinity(
                  validateParamsFiiSearch({ ...params, page: values.links.next })
                )
              }
            />
          )}
        </div>
        <SideModal id="sideFilter" isOpen={modalFilter} setState={setModalFilter}>
          <AsideFiltersFii
            onSelectedFilters={setParams}
            selectedFilters={params}
          />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </SideModal>
        </div>
      </ContentList>
  );
};

export default FiiPainel;
