import ScrollContainer from 'react-indiana-drag-scroll';
import styled from 'styled-components';

export const RendaPassivaContainer = styled.div`
  display: flex;
  padding: 16px 0px 24px 0px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  position: relative;
`;

export const HeaderPageContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: var(--Spacing-8, 8px);
  border: 1px solid var(--Gray-gray-100, #D4D6DB);
  background: var(--Interface-light-10, #FCFCFD);

  position: absolute;
  margin-left: 0%;
  margin-top: 5.5%;
  z-index:0;
  align-items: center;
  .filtros{
    margin-left: 0;
    margin-top: 0;
    display: flex;
    width: 100%;
    max-width: 916px;
    height: 120px;
    padding: 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: var(--Spacing-8, 8px);
    border: 1px solid var(--Gray-gray-100, #D4D6DB);
    background: var(--Main-white, #FFF);
    span {
      color: var(--Gray-gray-800, #40444C);

      /* Body/Large/SemiBold */
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 27px */
      width: 100%;
  }
  }
  
`;

export const TabContent = styled(ScrollContainer)`
  margin-left: 0;
  margin-top: -6%;
  display: flex;
  align-items: center;
  gap: var(--Spacing-8, 8px);
  position: absolute;

 
  background: transparent;
  z-index: 0;

  @media (max-width: 480px) {
    gap: 0;
  }
`;

interface IPropsButton {
  isActive: boolean;
}

export const ButtonTab = styled.button<IPropsButton>`
  display: flex;
  padding-left: 44px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  margin-top: -5%;
  all: unset;
  background: ${(props) =>
    props.isActive ? '#fff' : '#F7F7F8'};
  padding: 0.675rem 0.875rem;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom: ${(props) =>
    props.isActive ? 'transparent' : '1px solid #D4D6DB'};
  
  border-right: 1px solid var(--Gray-gray-100, #D4D6DB);
  border-left: 1px solid var(--Gray-gray-100, #D4D6DB);
  border-top: ${(props) =>
    props.isActive ? '3px solid #EC6643' : '1px solid #D4D6DB'};
  height: 24px;
  width: 100%;
  max-width: 20rem;

  font-weight: 500;
  font-size: 1.125rem;
  text-align: center;
  color: ${(props) =>
    props.isActive ? '#EC6643' : props.theme['gray-300']};
  white-space: nowrap;
  @media (max-width: 480px) {
    /* padding: 1rem 0.5rem; */
    font-size: 0.75rem;
  }
`;
