import { ArrowLeft } from '@phosphor-icons/react';

import {
  ButtonBack,
  ButtonSelectedFilter,
  PlanListContent,
  SignPlanContainer,
  SignPlanHeader,
  TabFilterStyle,
} from './styles';

import { useEffect, useState } from 'react';
import axios from 'axios';
import { userRoutes } from '../../../../../../../utils/RoutesApi';
import { CardPlan } from './components/CardPlan';
import { FormPlanStep } from './components/FormPlanStep';
import { Heading } from '../../../../../../../components/DefaultStylesTypography';
import { baseURL } from '../../../../../../../utils/baseURL';
import Footer from '../../../../../../layouts/Footer';
import { BannerContent, BannerContent2 } from '../../../../homeStyles';
import BFBanner from '../../../../../../../assets/business/BannerFimdeAno.png';

interface IPropsSignPlan {
  currentPlanId: number;
  onBackPaymentScreen: () => void;
  onFinishPlan: () => void;
}

export interface IPlanProps {
  amount: number;
  days: number;
  description: string;
  discount: string;
  id: string;
  name: string;
  trial_days: number;
  type: 'Mensal' | 'Anual' | 'Free';
}
export interface IPropsNewPlan {
  id: string;
  title: string;
  value: number;
  type: 'Anual' | 'Mensal' | 'Free';
  information: string | null;
  is_active: boolean;
  discount: string;
  days: string;
  manageUrl: string;

  funcionalities: Array<{
    label: string;
    disabled: boolean;
  }>;
}

export function SignPlan({
  currentPlanId,
  onBackPaymentScreen,
  onFinishPlan,
}: IPropsSignPlan) {
  const [filterTypePlan, setFilterTypePlan] = useState<'Mensal' | 'Anual'>(
    'Anual'
  );

  const [step, setStep] = useState(0);

  const [listPlans, setListPlans] = useState<IPropsNewPlan[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<IPropsNewPlan>({
    funcionalities: [],
    id: '0',
    information: null,
    title: '',
    type: 'Free',
    is_active: false,
    value: 0,
    days: '0',
    discount: '0',
    manageUrl: '',
  });

  useEffect(() => {
    getPlans();
  }, []);

  async function getPlans() {
    const response = await axios.get(`${baseURL}${userRoutes.PLAN_ROUTER_V3}`);
    setListPlans(
      response.data.sort((a: IPropsNewPlan, b: IPropsNewPlan) => {
        const idA = Number(a.value);
        const idB = Number(b.value);

        if (idA < idB) {
          return -1;
        }
        return true;
      })
    );
  }

  function handleBackButton() {
    if (selectedPlan.id === '0') {
      onBackPaymentScreen();
    } else if (step === 0) {
      setSelectedPlan({
        funcionalities: [],
        id: '0',
        information: null,
        title: '',
        type: 'Free',
        is_active: false,
        value: 0,
        days: '0',
        discount: '0',
        manageUrl: '',
      });
    } else if (step === 1) {
      setStep(0);
    } else if (step === 2) {
      setStep(1);
    }
  }

  const premiumPlanId = listPlans.find(
    (plan) =>
      plan.title.toLowerCase().includes('premium') && plan.type === 'Anual'
  );

  return (
    <SignPlanContainer>
      <ButtonBack onClick={handleBackButton}>
        <ArrowLeft weight="bold" /> Voltar
      </ButtonBack>
      {selectedPlan.id === '0' ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            overflow: 'auto',
            marginBottom: '1rem',
          }}
        >
          {' '}
          {/*Alteração, colocar o header e navbar para a parte de assinaturas*/}
          <SignPlanHeader>
            {/* <span>
              Venha descobrir como é fácil e rentável investir com inteligência!
            </span>
            <h5>
              Descubra os planos ideais para você com ofertas incríveis e preços
              acessíveis que cabem perfeitamente no seu orçamento!
            </h5> */}
            {/* <BannerContent2 src={BFBanner} /> */}
            <Heading className="titulo" as="h3" variant="tertiary">
              Venha descobrir como é fácil e rentável investir com inteligência!
            </Heading>
            <Heading className="titulo" as="h3" variant="tertiary">
              Descubra os planos ideais para você com ofertas incríveis e preços
              acessíveis que cabem perfeitamente no seu orçamento!
            </Heading>
            <TabFilterStyle>
              <ButtonSelectedFilter
                isActive={filterTypePlan === 'Mensal'}
                onClick={() => setFilterTypePlan('Mensal')}
              >
                Pagamento mensal
              </ButtonSelectedFilter>
              <ButtonSelectedFilter
                isActive={filterTypePlan === 'Anual'}
                onClick={() => setFilterTypePlan('Anual')}
              >
                Pagamento anual
              </ButtonSelectedFilter>
              <span>20% de desconto </span>
            </TabFilterStyle>
            <p> {filterTypePlan === 'Anual' ? '' : ''}</p>
          </SignPlanHeader>
          {/* {window.screen.width <= 1100 && loaded && instanceRef.current && (
            <div className="dots">
              {[
                //@ts-ignore
                ...Array(
                  instanceRef.current.track.details.slides.length -
                    //@ts-ignore
                    Math.floor(instanceRef.current.options.slides.perView) +
                    1
                ).keys(),
              ].map((idx) => {
                return (
                  <button
                    key={idx}
                    onClick={() => {
                      instanceRef.current?.moveToIdx(idx);
                    }}
                    className={'dot' + (currentSlide === idx ? ' active' : '')}
                  ></button>
                );
              })}}
            </div>
            // ) */}
          {listPlans.length > 0 && (
            <PlanListContent
            // ref={sliderRef}
            // className={`${window.screen.width <= 1100 && 'keen-slider'}`}
            >
              {listPlans
                .filter((plan) => plan.is_active)
                .filter(
                  (plan) => plan.type === filterTypePlan || plan.type === 'Free'
                )
                .map((plan) => (
                  <CardPlan
                    key={plan.id}
                    //@ts-ignore
                    plan={plan}
                    currentPlanId={currentPlanId}
                    setSelectedPlan={(selectedId) =>
                      setSelectedPlan(selectedId)
                    }
                    onClose={onBackPaymentScreen}
                    idPremiumPlan={premiumPlanId?.id || '1698753'}
                  />
                ))}
            </PlanListContent>
          )}
          <Footer></Footer>
        </div>
      ) : (
        <FormPlanStep
          selectedPlan={selectedPlan}
          setStep={setStep}
          step={step}
          onFinishPlan={onFinishPlan}
          onClose={onBackPaymentScreen}
          onClearPlan={() =>
            setSelectedPlan({
              funcionalities: [],
              id: '0',
              information: null,
              title: '',
              type: 'Free',
              is_active: false,
              value: 0,
              days: '0',
              discount: '0',
              manageUrl: '',
            })
          }
        />
      )}
    </SignPlanContainer>
  );
}
