import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
`;

export const IsLinkAccountB3 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  background-color: #000;
  padding: 1rem;
  border-radius: 8px;
  max-width: 600px;
  width: 100%;
`;
export const IsNotLinkAccountB3 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  background-color: #fff;
  padding: 1rem;
  border-radius: 8px;
  max-width: 600px;
  width: 100%;
`;
