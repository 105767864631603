export const APP_NAME = 'Social';
export const IS_HANDHELD = 'ontouchstart' in document.documentElement;

// Routing
export const route = {
  login: '/login/',
  followers: (slug) => `/users/${slug}/followers/`,
  following: (slug) => `/users/${slug}/following/`,
  home: '/home/',
  index: '/',
  notifications: '/notifications/',
  postDetail: (postId) => `/post/${postId}/`,
  postLikes: (postId) => `/post/${postId}/likes/`,
  postDetailLikes: (postId) => `/post/${postId}/likes/`,
  profilePosts: (slug) => `/users/${slug}/`,
  profileLikes: (slug) => `/users/${slug}/likes/`,
  profileReplies: (slug) => `/users/${slug}/replies/`,
  recommendedPosts: '/recommended-posts/',
  recommendedUsers: '/recommended-users/',
  register: '/register/',
  search: '/search/',
  settings: '/settings/',
};

export const chartHelpText = {
  lucro: `Lucro líquido é o ganho que a empresa obtém após realizar todos os descontos obrigatórios e deduzir todos os gastos das operações em relação às suas receitas. A lucratividade é um dos principais indicadores econômicos da empresa. O grafico apresenta o valor do ultimo balanço anual.`,
  divida:
    'Dívida líquida é o somatório dos empréstimos e financiamentos da empresa, deduzidos de seu caixa e equivalentes. Um baixo endividamento pode representar boa saúde financeira, ou seja, que a organização possui caixa suficiente para quitar seus compromissos. O grafico apresenta o valor do ultimo balanço anual.',
  dividendos:
    'Para aqueles que buscam renda passiva por meio dos investimentos em ações, analisar o pagamento de dividendos é imprescindível. O dividendo representa a parte do lucro da empresa que é destinada aos acionistas, é uma das principais formas que as empresas tem de remunerar seus sócios. O grafico apresenta o valor do ultimo balanço anual. ',
  ebitida:
    'O EBITDA representa os resultados obtidos pela empresa exclusivamente relacionados à sua atividade fim e leva em consideração o lucro obtido antes do desconto de impostos, juros, depreciações e amortizações.  O crescimento recorrente do EBITDA pode indicar que o negócio vem apresentando melhoria de eficiência e produtividade. O grafico apresenta o valor do ultimo balanço anual.',
  patrimonio:
    'O patrimônio líquido é um indicador que representa a diferença entre o ativo (Bens, direitos) e o passivo (obrigações) das empresas, ou seja, demonstra a subtração entre os bens e direitos que uma empresa possui em relação às suas obrigações. O grafico apresenta o valor do ultimo balanço anual.',
  caixa:
    'O Fluxo  de caixa é um indicador muito importante para mensurar o desempenho de uma empresa, pois ele mostra o saldo das entradas e saídas, e, por consequência, a disponibilidade de caixa disponível na empresa. O grafico apresenta o valor do ultimo balanço anual.',
  dividaEbitida:
    'Dívida líquida/EBITDA é um indicador financeiro que serve para analisar o endividamento da empresa. Seu resultado indica o número de anos que ela levaria para quitar sua dívida com base no EBITDA atual. O grafico apresenta o valor do ultimo balanço anual.',
  vacancia:
    ' A taxa de vacância mede a intensidade da desocupação do imóvel. A vacância física indica o percentual de imóveis sem inquilinos no portfólio do fundo. A vacância financeira indica o percentual estimado da receita que se deixa de arrecadar com a atual taxa de vacância física. O grafico apresenta o valor do ultimo balanço anual.',
  dividendo:
    ' O Dividendo é um indicador que mede a performance de um investimento de acordo com os proventos pagos aos seus cotistas. O Dividend Yeld é a soma de todos os dividendos pagos pelo fundo nos últimos 12 meses, dividida pela cotação atual do fundo. Esse indicador permite avaliar a performance do fundo no pagamento de proventos e pode também ser um sinal de uma gestão eficiente dos recursos. O grafico apresenta o valor do ultimo balanço anual.',
  contratos:
    'Indica o prazo para vencimento dos contratos. Um percentual maior acima de 36 meses pode significar estabilidade para os rendimentos do fundo.  ',
  imoveis:
    'A quantidade de imóveis e sua distribuição geográfica são critérios a serem analisados como indicador da diversificação do FII. O Recomendado é que o Fundo tenha vários imóveis e que eles estejam em mais de um Estado. O grafico apresenta o valor do ultimo balanço anual.',
  ROE: 'O ROE (Return On Equity), Retorno sobre Patrimônio Líquido, em português, é a relação entre o lucro líquido e o patrimônio líquido. Trata-se de um indicador da eficiência da empresa em gerar lucro a partir dos recursos que tem disponíveis. O grafico apresenta o valor do ultimo balanço anual.',
  DY: 'Indicador que relaciona os proventos pagos ao preço atual do ativo. O grafico apresenta o valor do ultimo balanço anual.',
  valorMercado:
    'O valor de mercado de um fundo de investimento imobiliário representa o preço atual de suas cotas no mercado financeiro, baseado na oferta e demanda de investidores. Reflete a percepção dos investidores em relação ao potencial de retorno do fundo e pode variar ao longo do tempo, de acordo com as condições do mercado financeiro e imobiliário. O P/VP representa a relação entre o preço de mercado da cota e o seu valor patrimonial. Essa métrica é útil para avaliar se o fundo está sendo negociado com um desconto ou prêmio em relação ao valor de seus ativos imobiliários. Um P/VP abaixo de 1 indica que o fundo está sendo negociado com desconto em relação ao valor de seus ativos, enquanto um P/VP acima de 1 indica que o fundo está sendo negociado com prêmio.',
  valorPatrimonial:
    'O Valor Patrimonial é o valor dos ativos imobiliários detidos pelo fundo, calculado com base em informações contábeis. O P/VP, por sua vez, é a relação entre o preço de mercado da cota e o seu Valor Patrimonial, ou seja, é uma medida que indica se o fundo está sendo negociado com desconto ou prêmio em relação ao valor de seus ativos. Um P/VP abaixo de 1 indica que o fundo está sendo negociado com desconto em relação ao seu Valor Patrimonial, enquanto um P/VP acima de 1 indica que o fundo está sendo negociado com prêmio.',
};

const messagePosfix='\nNão deixe de aproveitar todas as funcionalidades do iBox6.\nAinda dá tempo de renovar e continuar sem interrupções!\nEstamos aqui para ajudar no que precisar!'
export const expireMensages = {
  '7':'Sua assinatura expira em 7 dias!' + messagePosfix,
  '3':'Sua assinatura expira em 3 dias!' + messagePosfix,
  '2':'Sua assinatura expira em 2 dias!' + messagePosfix,
  '0':'Sua assinatura expira Hoje!' + messagePosfix,
}
