import styled from 'styled-components';

interface IPropsCountdown {
  showCountdown: boolean;
}

export const CountDownContainer = styled.div<IPropsCountdown>`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 1000000;
  border-radius: 4px;
  width: 15rem;
  background: #303F4B;
  display: ${(props) => (props.showCountdown ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: auto;
  cursor: pointer;

  p {
    margin: 0;
    /* margin-top: 0.5rem; */
    color: ${(props) => props.theme.white};
    font-size: 1rem;

    strong {
      font-size: 1.25rem;
      font-weight: 500;
    }
    span {
      font-size: 0.75rem;
    }
  }

  header {
    color: ${(props) => props.theme.white};
    text-align: center;
    width: 100%;
    font-size: 1rem;

    padding: 0.5rem;
    border-radius: 4px 4px 0 0;
    position: relative;
    display: flex;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);

    h1 {
      font-size: 1.5rem;
      color: ${(props) => props.theme.white};
      margin: 0;
    }
    button {
      position: absolute;
      top: 0;
      right: 0.2rem;
      background: transparent;
      color: ${(props) => props.theme.white};
      font-size: 1rem;
      border: 0;
      padding: 0;
    }
  }

  .redContentColor {
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 10px 80px 120px red;
    z-index: -10;
  }
  .yellowContentColor {
    position: absolute;
    bottom: 0;
    left: 0;
    box-shadow: 0 10px 80px 120px ${(props) => props.theme['brand-secondary']};
    z-index: -10;
  }
  .blueContentColor {
    position: absolute;
    bottom: 50%;
    right: 0;
    box-shadow: 0 10px 80px 120px ${(props) => props.theme.primary};
    z-index: -10;
  }

  .countdownContent {
    /* padding: 0.5rem; */
    text-align: center;
    height: 0;
    transform: translateY(30px);
    transition: all 1s;
    overflow: hidden;

    p {
      margin: 0;
      font-size: 0;
      color: ${(props) => props.theme.white};
    }
  }

  &:hover {
    .countdownContent {
      height: auto;
      transform: translateY(0);
      padding: 0.5rem;
      animation-name: liq;
      animation-duration: 1s;
      animation-fill-mode: forwards;

      p {
        font-size: 1rem;
      }
    }
  }

  @keyframes liq {
    0% {
      height: 0px;
      /* transform: rotate(0deg); */
    }
    10% {
      height: 0.75rem;
    }
    20% {
      height: 1.5rem;
      /* transform: rotate(5deg); */
    }
    30% {
      height: 2.25rem;
    }
    40% {
      height: 3rem;
      /* transform: rotate(-5deg); */
    }
    50% {
      height: 3.75rem;
    }
    60% {
      height: 4.5rem;
      /* transform: rotate(5deg); */
    }
    100% {
      height: auto;
      /* transform: rotate(0deg); */
    }
  }
`;

export const CountdownContent = styled.div``;
