import { useForm } from 'react-hook-form';
import { Slider } from 'antd';
import { useEffect, useState } from 'react';
import { NotePencil, Pencil } from '@phosphor-icons/react';
import {
  CalcButton,
  ContainerJurosCompostos,
  ContentButtonValue,
  ContentDataAndGraphic,
  ContentFilterDateAndTitle,
  ContentInputPreFixed,
  ContentSimulador,
  InputContent,
  MonthlyInputContent,
} from './styles';
import './style.css'

import { formatedAllValueCurrency } from '../../../../utils/formatedAllvalueCurrency';
import { transformMaskCurrency } from '../../../../utils/transformMaskCurrency';
import { handleToast } from '../../../../components/Toast';
import { GraphicCalc } from './components/Graphic';
import { ButtonDesign } from '../../../../components/ButtonDesign';
import { defaultValueGraphic } from './utils/defaultValueGraphic';
import { ModalDesigner } from '../../../../components/ModalDesigner';
import { FormEditJuros } from './components/FormEditJuros';

interface IPropsResultValues {
  interest: number[];
  mount: number[];
  totalInvest: number[];
  isGraphic: boolean;
  total: number;
}

interface IPropsSubmitForm {
  initial: number;
  monthlyInvestment: number;
  period: number;
  interest: number;
  typePeriod: 'monthly' | 'year';
  typePeriodInterest: 'monthly' | 'year';
}

export function JurosCompostos() {
  const {
    watch,
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();
  const [initialInvestment, setInitialInvestment] = useState<number | string>(
    '1.500,00'
  );
  const [monthlyInvestment, setMonthlyInvestment] = useState<number | string>(
    '500,00'
  );
  const [periodInvestment, setPeriodInvestment] = useState(10);
  const [jurosInvestment, setJurosInvestment] = useState(0.8);
  const [resultValues, setResultValues] =
    useState<IPropsResultValues>(defaultValueGraphic);
  const [rendaPassivaState, setRendaPassivaState] = useState('R$ 832,09');
  const [typeJurosRenda, setTypeJurosRenda] = useState<'monthly' | 'year'>(
    'monthly'
  );
  const [typeJurosRendaCalc, setTypeJurosRendaCalc] = useState<
    'monthly' | 'year'
  >('monthly');
  const [jurosRendaValue, setJurosRendaValue] = useState(0.8);
  const [showEditRendaPassiva, setShowEditRendaPassiva] = useState(false);
  const typePeriodInvestment = watch('typePeriod');
  const [totalAcumulado, setTotalAcumulado] = useState<number>(
    defaultValueGraphic.mount[defaultValueGraphic.mount.length - 1]
  );

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      rendaPassiva(totalAcumulado);
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [totalAcumulado, jurosRendaValue, typeJurosRenda, typeJurosRendaCalc]);

  const totalAccumulated = {
    showFormated: formatedAllValueCurrency(
      resultValues.mount.length >= 1
        ? resultValues.mount[resultValues.mount.length - 1]?.toFixed(2)
        : 0
    ),
    realValue: transformMaskCurrency(
      resultValues.mount[resultValues.mount.length - 1] ?? 0
    ),
  };
  const totalInvested = {
    showFormated: formatedAllValueCurrency(
      resultValues.totalInvest[resultValues.totalInvest.length - 1]?.toFixed(
        2
      ) ?? 0
    ),
    realValue: transformMaskCurrency(
      resultValues.totalInvest[resultValues.totalInvest.length - 1] ?? 0
    ),
  };
  const totalInterest = {
    showFormated: formatedAllValueCurrency(
      Number(
        resultValues.interest[resultValues.interest.length - 1]?.toFixed(2)
      ) ?? 0
    ),
    realValue: transformMaskCurrency(
      resultValues.interest[resultValues.interest.length - 1] ?? 0
    ),
  };

  const renda = rendaPassivaState;

  function rendaPassiva(totalAcumulado: number) {
    let total = totalAcumulado;
    let juros: number = jurosRendaValue;
    if (typeJurosRendaCalc === 'year') {
      let taxJuros = jurosRendaValue / 100;

      let a = 1 + taxJuros;
      let potencial = 1 / 12;
      let b = Math.pow(a, potencial);
      b = b - 1;
      b = b * 100;

      juros = parseFloat(b.toFixed(4) ?? 0);

      total = Number(((total * juros) / 100).toFixed(2));
    } else {
      juros = 100 / juros;
      total = Number((total / juros).toFixed(2));
    }
    setRendaPassivaState(transformMaskCurrency(total));
  }

  async function submit(data: IPropsSubmitForm) {
    setResultValues({
      total: 0,
      interest: [],
      mount: [],
      totalInvest: [],
      isGraphic: true,
    });
    if (initialInvestment) {
      data.initial = Number(
        initialInvestment
          .toString()
          .replace(/\D/g, '')
          .replace(/(\d)(\d{2})$/, '$1.$2') ?? 0
      );
    } else {
      data.initial = 0;
    }
    data.interest = Number(jurosInvestment);
    data.monthlyInvestment = Number(
      monthlyInvestment
        .toString()
        .replace(/\D/g, '')
        .replace(/(\d)(\d{2})$/, '$1.$2')
        ? monthlyInvestment
            .toString()
            .replace(/\D/g, '')
            .replace(/(\d)(\d{2})$/, '$1.$2')
        : 0
    );
    data.period = periodInvestment;

    if (!data.period) {
      return handleToast(true, 'Informe o período a ser calculado');
    }
    if (!data.interest) {
      return handleToast(true, 'Informe a taxa de juros');
    }

    let period = data.period;
    if (data.typePeriod === 'year') {
      period = period * 12;
    }

    setResultValues((state) => {
      return {
        ...state,
        total: Number(period),
      };
    });

    let juros = data.interest / 100;
    juros = 1 + juros;
    if (data.typePeriodInterest === 'year') {
      juros = Math.pow(juros, 1 / 12);
    }
    let total = data.initial;
    let mount = data.initial;

    let valores: IPropsResultValues = {
      interest: [],
      totalInvest: [],
      isGraphic: true,
      mount: [],
      total: Number(period),
    };

    for (let i = 1; i <= period; i++) {
      let totalJuros = mount * juros - total;
      if (i === 1) {
        mount = Number((mount * juros).toFixed(2)) + data.monthlyInvestment;
      } else {
        mount = Number((mount * juros).toFixed(2)) + data.monthlyInvestment;
      }
      total = total + data.monthlyInvestment;
      valores = {
        ...valores,
        interest: [...valores.interest, totalJuros],
        totalInvest: [...valores.totalInvest, total],
        isGraphic: true,
        mount: [...valores.mount, mount],
      };

      setResultValues((state) => {
        return {
          ...state,
          interest: [...state.interest, totalJuros],
          totalInvest: [...state.totalInvest, total],
          isGraphic: true,
          mount: [...state.mount, Number(mount)],
        };
      });

      if (i === Number(period)) {
        setTotalAcumulado(
          Number(valores.mount[valores.mount.length - 1].toFixed(2))
        );
      }
    }
  }

  function handleChangeValueInitial(value: number) {
    setInitialInvestment(
      value
        .toString()
        .replace(/\D/g, '')
        .replace(/(\d)(\d{2})$/, '$1,$2')
        .replace(/(?=(\d{3})+(\D))\B/g, '.')
    );
  }
  function handleChangeValueForMonthly(value: number) {
    setMonthlyInvestment(
      value
        .toString()
        .replace(/\D/g, '')
        .replace(/(\d)(\d{2})$/, '$1,$2')
        .replace(/(?=(\d{3})+(\D))\B/g, '.')
    );
  }
  function handleChangePeriod(value: number) {
    setPeriodInvestment(value);
  }
  function handleChangeJuros(value: number) {
    setJurosInvestment(value);
    // setJurosRendaValue(value);
  }

  const tooltip: any = {
    formatter: (val: number) => transformMaskCurrency(val),
  };

  const handleInputChange = (event: any) => {
    const rawValue = event.target.value;

    // Remover caracteres não numéricos
    const numericValue = rawValue.replace(/[^\d]/g, '');

    // Adicionar ponto decimal da direita para a esquerda
    let formattedValue = numericValue;
    if (numericValue.length > 2) {
      const lastTwoDigits = numericValue.slice(-2);
      const remainingDigits = numericValue.slice(0, -2);
      if (remainingDigits === '00') {
        formattedValue = '0.' + lastTwoDigits;
      } else {
        formattedValue =
          remainingDigits.replace(/^0+/, '') + '.' + lastTwoDigits;
      }
    } else if (numericValue.length === 2 && numericValue[0] !== '0') {
      formattedValue = '0.' + numericValue;
    } else if (numericValue.length === 1) {
      formattedValue = '0.0' + numericValue;
    } else {
      formattedValue = '0.00';
    }

    // Atualizar o estado
    setJurosInvestment(formattedValue);
  };

  return (
    <ContainerJurosCompostos>
      {/* @ts-ignore */}
      <ContentSimulador onSubmit={handleSubmit(submit)}>
        <label>Qual valor você gostaria de investir?</label>
        <InputContent>
          <ContentInputPreFixed className='ContentInputPreFixed'>
            <span>R$</span>
            <input
              className='input'
              type="text"
              value={initialInvestment}
              onChange={(e: any) =>
                setInitialInvestment(
                  e.target.value
                    .replace(/\D/g, '')
                    .replace(/(\d)(\d{2})$/, '$1,$2')
                    .replace(/(?=(\d{3})+(\D))\B/g, '.')
                )
              }
            />
          </ContentInputPreFixed>
        </InputContent>
        <br />
        <label>Qual valor você gostaria de depositar por mês?</label>
        <InputContent>
          <ContentInputPreFixed className='ContentInputPreFixed'>
            <span>R$</span>
            <input
              className='input'
              type="text"
              value={monthlyInvestment}
              onChange={(e: any) =>
                setMonthlyInvestment(
                  e.target.value
                    .replace(/\D/g, '')
                    .replace(/(\d)(\d{2})$/, '$1,$2')
                    .replace(/(?=(\d{3})+(\D))\B/g, '.')
                )
              }
            />
          </ContentInputPreFixed>
        </InputContent>
        <br />
        <label>Quanto tempo deixaria seu dinheiro investido?</label>
        <ContentFilterDateAndTitle>
          <MonthlyInputContent className='MonthlyInputContent'>
            <input
              className='input'
              type="number"
              value={periodInvestment}
              onChange={(e: any) => setPeriodInvestment(e.target.value)}
            />
          </MonthlyInputContent>
          <select className='select' defaultValue="year" {...register('typePeriod')}>
            <option value="monthly">Meses</option> 
            <option value="year">Anos</option>
          </select>
        </ContentFilterDateAndTitle>
        <br />
        <label>Qual valor da taxa de juros?</label>
        <ContentFilterDateAndTitle>
          <MonthlyInputContent className='MonthlyInputContent'>
            <input
              className='input'
              value={jurosInvestment}
              type="number"
              onChange={handleInputChange}
            />

            <span>%</span>
          </MonthlyInputContent>
          <select
            className='select'
            defaultValue="monthly"
            {...register('typePeriodInterest')}
            onChange={(e) =>
              setTypeJurosRenda(e.target.value as 'year' | 'monthly')
            }
          >
            <option  value="monthly">Mensal</option>
            <option value="year">Anual</option>
          </select>
        </ContentFilterDateAndTitle>
        <br />
        <div
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <CalcButton>CALCULAR</CalcButton>
        </div>
      </ContentSimulador>

      <ContentDataAndGraphic>
        <h4 style={{color:'#40444C'}}>Resultado</h4>
        <span>
          Em {periodInvestment}{' '}
          {typePeriodInvestment === 'year' ? 'Anos' : 'Meses'}, você teria:
        </span>
        <h2 style={{
          display: 'flex',
          padding: '8px 16px',
          alignItems: 'center',
          gap: '2px',
          alignSelf: 'stretch',
          width: '100%',
          margin: '0',
          fontWeight: 500,
          fontSize: '2.5rem', /*24px*/
          color: '#858fa1', // Cor do totalAccumulated
          borderBottom: '4px solid #EC6643' 
        }}>
          <strong
            style={{
              fontFamily: 'Poppins',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '150%',
              color: '#6A717E' // Cor específica para o <strong>
            }}>R$</strong>
          <a>{totalAccumulated.realValue.replace('R$', '')}</a>
        </h2>
        <br />
        <p style={{color: '#6A717E'}}>
          Total investido: <strong style={{color: '#858fa1'}}>{totalInvested.realValue}</strong>
        </p>
        <p style={{color: '#6A717E'}}>
          Total juros: <strong style={{color: '#858fa1'}}>{totalInterest.realValue}</strong>
        </p>
        <p style={{color: '#6A717E'}}>
          Renda passiva mensal: <strong style={{color: '#858fa1'}} className="withButton">{renda}</strong>
          <button type="button" onClick={() => setShowEditRendaPassiva(true)}>
            <Pencil weight="fill" width={'20px'} height={'20px'}/>{' '}
          </button>
        </p>
        <br />
        <p style={{ 
          fontSize: '16px',
          color: '#6A717E',
          marginTop: '0%',
          }}>
          Juros compostos ao longo do tempo
        </p>
        <div style={{ width: '100%' }}>
          {resultValues.isGraphic &&
            resultValues.mount.length === resultValues.total && (
              <GraphicCalc
                valuesGraphic={resultValues}
                isLoading={isSubmitting}
              />
            )}
        </div>
      </ContentDataAndGraphic>

      <ModalDesigner
        id="editJuros"
        isOpen={showEditRendaPassiva}
        setState={setShowEditRendaPassiva}
        size="md"
        title="Calcular Renda Passiva"
        titleColor="#FFF"
      >
        <FormEditJuros
          rendaPassivaValue={renda}
          amount={transformMaskCurrency(totalAcumulado)}
          editTotalAmount={setTotalAcumulado}
          jurosRendaPassiva={jurosRendaValue}
          setJurosRendaPassiva={setJurosRendaValue}
          typeJurosRenda={typeJurosRendaCalc}
          setTypeJurosRenda={setTypeJurosRendaCalc}
        />
      </ModalDesigner>
    </ContainerJurosCompostos>
  );
}
