import axios from 'axios';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { AsideContentFilters, ContentInputFilters } from './styles';
import { baseURL } from '../../../../../../utils/baseURL';

interface IPropsAsideFiltersCompareAssets {
  selectedFilters: {
    setor: string;
    tipo_gestao: string;
    tipofii: string;
  };
  setSelectedFilters: Function;
}

interface IPropsFiltersFii {
  setores: {
    value: string;
    label: string;
  }[];
  administradores: {
    value: string;
    label: string;
  }[];
  tipos_gestao: {
    value: string;
    label: string;
  }[];
}

export function AsideFiltersCompareAssets({
  selectedFilters,
  setSelectedFilters,
}: IPropsAsideFiltersCompareAssets) {
  const [allFilters, setAllFilters] = useState<IPropsFiltersFii>({
    administradores: [],
    setores: [],
    tipos_gestao: [],
  });
  const [setor, setSetor] = useState(selectedFilters.setor);
  const [gestao, setGestao] = useState(selectedFilters.tipo_gestao);
  const [type, setType] = useState('');

  useEffect(() => {
    getListFilters();
  }, []);

  useEffect(() => {
    setSelectedFilters({
      setor: setor,
      tipo_gestao: gestao,

      tipofii: type,
    });
  }, [setor, gestao, type]);

  async function getListFilters() {
    try {
      const response = await axios.get(`${baseURL}/Finance/fiis-filtros`);
      setAllFilters({
        administradores: [
          {
            value: '',
            label: 'Todos',
          },
          ...response.data.administradores.map((item: string) => {
            return {
              value: item,
              label: `${item.substring(0, 1).toUpperCase()}${item
                .substring(1)
                .toLowerCase()}`,
            };
          }),
        ],
        setores: [
          {
            value: '',
            label: 'Todos',
          },
          ...response.data.setores.map((item: string) => {
            return {
              value: item,
              label: `${item.substring(0, 1).toUpperCase()}${item
                .substring(1)
                .toLowerCase()}`,
            };
          }),
        ],
        tipos_gestao: [
          {
            value: '',
            label: 'Todos',
          },
          ...response.data.tipos_gestao.map((item: string) => {
            return {
              value: item,
              label: `${item.substring(0, 1).toUpperCase()}${item
                .substring(1)
                .toLowerCase()}`,
            };
          }),
        ],
      });
    } catch (error) {}
  }

  const returnSelectValue = (
    id: string,
    arr: {
      value: string;
      label: string;
    }[]
  ) => {
    if (id) {
      let obj = arr?.map((item) => {
        if (item?.value === id) {
          return {
            value: item.value,
            label: item.label,
          };
        }
      });
      return obj;
    } else {
      return null;
    }
  };

  const arrSegList = [
    {
      value: '',
      label: 'Todos',
    },
    {
      value: 'Papel',
      label: 'Papel',
    },
    {
      value: 'Tijolo',
      label: 'Tijolo',
    },
  ];

  return (
    <AsideContentFilters>
      <span>Filtros</span>
      <ContentInputFilters>
      <label style={{ position: 'absolute', top: '5px', left: '65px',zIndex: '2', }}>Setor</label>
      <Select
        className="select"
        options={allFilters.setores}
        //@ts-ignore
        onChange={(e) => {
          //@ts-ignore
          setSetor(e.value);
        }}
        value={returnSelectValue(setor, allFilters.setores)}
        placeholder="Todos"
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderTop: state.isFocused ? 'transparent' : 'transparent',
            borderRight: state.isFocused ? 'transparent' : 'transparent',
            borderLeft: state.isFocused ? 'transparent' : 'transparent',
            '&:hover': { 
              borderTop: 'transparent', // Remove a borda no hover 
              borderRight: 'transparent', // Remove a borda no hover 
              borderLeft: 'transparent', // Remove a borda no hover 
            },
            marginTop: '0%',
            marginLeft: '0%',
            display: 'flex',
            width: 250,
            height: 56,
            padding: '4px 4px 4px 12px',
            justifyContent: 'space-between',
            alignItems: 'center',
            flex: '1 0 0',
            borderBottom: '1px solid var(--Gray-gray-600, #6A717E)',
            background: 'transparent',
          }),
          container: (baseStyles) => ({
            ...baseStyles,
            borderRadius: 0,
            position: 'relative',
            
          }),
          input: (baseStyles) => ({
            ...baseStyles,
            color: 'none',
            fontSize: 16,
          }),
          placeholder: (base) => ({
            ...base,
            
          }),
          valueContainer: (base) => ({
            ...base,
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 8,
            paddingBottom: 8,
            
          }),
          indicatorSeparator: () => ({
            display: 'none',
            
          }),
          dropdownIndicator: (base) => ({
            ...base,
            color: '#808080',
            background: '#fff'
          }),
        }}
      />
      </ContentInputFilters>

      <ContentInputFilters>
        <label style={{ position: 'absolute', top: '5px', left: '65px',zIndex: '2', }}>Gestão</label>
        <Select
          className="select"
          options={allFilters.tipos_gestao}
          //@ts-ignore
          onChange={(e) => {
            //@ts-ignore
            setGestao(e.value);
          }}
          value={returnSelectValue(gestao, allFilters.tipos_gestao)}
          placeholder="Todos"
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderTop: state.isFocused ? 'transparent' : 'transparent',
              borderRight: state.isFocused ? 'transparent' : 'transparent',
              borderLeft: state.isFocused ? 'transparent' : 'transparent',
              '&:hover': { 
                borderTop: 'transparent', // Remove a borda no hover 
                borderRight: 'transparent', // Remove a borda no hover 
                borderLeft: 'transparent', // Remove a borda no hover 
              },
              marginTop: '0%',
              marginLeft: '0%',
              display: 'flex',
              width: 250,
              height: 56,
              padding: '4px 4px 4px 12px',
              justifyContent: 'space-between',
              alignItems: 'center',
              flex: '1 0 0',
              borderBottom: '1px solid var(--Gray-gray-600, #6A717E)',
              background: 'transparent',
            }),
            container: (baseStyles) => ({
              ...baseStyles,
              borderRadius: 0,
              position: 'relative', 
            }),
            input: (baseStyles) => ({
              ...baseStyles,
              color: 'none',
              fontSize: 16,
            }),
            placeholder: (base) => ({
              ...base,
            }),
            valueContainer: (base) => ({
              ...base,
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 8,
              paddingBottom: 8,
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
            dropdownIndicator: (base) => ({
              ...base,
              color: '#808080',
            }),
          }}
        />
      </ContentInputFilters>
      <ContentInputFilters>
        <label style={{ position: 'absolute', top: '5px', left: '65px',zIndex: '2', }}>Tipo de fundo</label>
        <Select
          className="select"
          options={arrSegList}
          //@ts-ignore
          onChange={(e) => {
            //@ts-ignore
            setType(e.value);
          }}
          value={returnSelectValue(type, arrSegList)}
          placeholder="Todos"
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderTop: state.isFocused ? 'transparent' : 'transparent',
              borderRight: state.isFocused ? 'transparent' : 'transparent',
              borderLeft: state.isFocused ? 'transparent' : 'transparent',
              '&:hover': { 
                borderTop: 'transparent', // Remove a borda no hover 
                borderRight: 'transparent', // Remove a borda no hover 
                borderLeft: 'transparent', // Remove a borda no hover 
              },
              marginTop: '0%',
              marginLeft: '0%',
              display: 'flex',
              width: 250,
              height: 56,
              padding: '4px 4px 4px 12px',
              justifyContent: 'space-between',
              alignItems: 'center',
              flex: '1 0 0',
              borderBottom: '1px solid var(--Gray-gray-600, #6A717E)',
              background: 'transparent',
            }),
            container: (baseStyles) => ({
              ...baseStyles,
              borderRadius: 0,
              position: 'relative', 
            }),
            input: (baseStyles) => ({
              ...baseStyles,
              color: 'none',
              fontSize: 16,
            }),
            placeholder: (base) => ({
              ...base,
            }),
            valueContainer: (base) => ({
              ...base,
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 8,
              paddingBottom: 8,
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
            dropdownIndicator: (base) => ({
              ...base,
              color: '#808080',
            }),
          }}
        />
      </ContentInputFilters>
    </AsideContentFilters>
  );
}
