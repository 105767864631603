import { useEffect, useState } from 'react';
import { PortfolioBalanceSheetStatistics } from '../../../components/PortfolioBalanceSheetStatistics';
import { Container, RowDiv, BannerContent, Header } from './homeStyles';
import { useNewTheme } from '../../../hooks/useNewTheme';
import { useActives } from '../../../hooks/useActives';
import { useTitleAndUser } from '../../../hooks/userTitle';
import { DataHomeResume } from './components/DataHomeResume';
import { TutorialIbox } from './components/TutorialIbox';
import { NewsComponent } from './components/NewsComponent';
import { Heading } from '../../../components/DefaultStylesTypography';
import { CongratulationsCreatingAccount } from './components/CongratulationsCreatingAccount';
import './customToastStyles.css';
import { ModalDesigner } from '../../../components/ModalDesigner';
import { string } from 'zod';
import { expireMensages } from '../../../social/constants';
import axios from 'axios';
import { userRoutes, walletRoutes } from '../../../utils/RoutesApi';
import moment from 'moment';
import { CarouselDashInfos } from './components/CarouselDashInfos';
import { useStrategy } from '../../pages/Wallet/hooks/StrategyHooks';
import axiosService from '../../../utils/axios';
import { baseURL } from '../../../utils/baseURL';
import { LoadingBanner } from './LoadingCard';
import BFBanner from '../../../assets/business/BannerFimdeAno.png';
import { HeaderContainer } from '../../../Header';

const Home = () => {
  const { user } = useTitleAndUser();
  const announcement = localStorage.getItem(process.env.REACT_APP_ANNOUNCEMENT);
  const route = window.location.pathname;
  const updateNewsAtt = localStorage.getItem(process.env.REACT_APP_NEWS);
  const [modalWelcome, setModalWelcome] = useState(
    () => route === '/dashboard/led'
  );
  const [isPromotion, setIsPromotion] = useState(
    () => announcement !== 'close'
  );
  const { setTitle } = useTitleAndUser();
  const { shortSidebar } = useNewTheme();
  const { percentage, loadingActive } = useActives();
  const [strategyPercentage, setStrategyPercentage] = useState({});
  const [strategyPercentageLoading, setStrategyPercentageLoading] =
    useState(false);

  const isLead = localStorage.getItem('isLead');
  const isLeadJSON = isLead ? JSON.parse(isLead) : [];
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [plan, setPlan] = useState({});

  const checkPlanExpiry = (plan) => {
    try {
      if (plan.currentPeriodEnd) {
        const daysLeft = moment(plan?.currentPeriodEnd).diff(moment(), 'days');

        const messageKey = String(daysLeft);
        const message = expireMensages[messageKey];
        if (message) {
          setModalMessage(message);
          setModalVisible(true);
        }
      }
    } catch (error) {}
  };

  async function handlePayment() {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}${userRoutes.STATUS_PAYMENT}`)
        .then((response) => {
          setPlan(response.data);
        })
        .catch((err) => {
          //@ts-ignore

          setPlan(null);
        });
    } catch (error) {}
  }

  if (!isLeadJSON.includes(user.email) && route === '/dashboard/led') {
    setTimeout(() => {
      localStorage.setItem(
        'isLead',
        JSON.stringify([...isLeadJSON, user.email])
      );
    }, 5000);
  }

  const getStrategyData = async () => {
    try {
      setStrategyPercentageLoading(true);
      const response = await axios.get(
        `${baseURL}/api${walletRoutes.WALLET_GET_CONFIG_STRATEGY}`
      );
      setStrategyPercentage({
        fii: response?.data?.data?.PorcentagemFII || 0,
        action: response?.data?.data?.PorcentagemAcoes,
        rendaFixa: response?.data?.data?.PorcentagemRendaVariavel,
      });
      setStrategyPercentageLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handlePayment();
    getStrategyData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTitle('Página inicial');
    localStorage.removeItem('news_v_1.0.9');
    localStorage.removeItem('promotion');

    if (announcement !== 'close') {
      localStorage.setItem(process.env.REACT_APP_ANNOUNCEMENT, 'close');
    }
    if (updateNewsAtt !== 'false') {
      localStorage.setItem(process.env.REACT_APP_NEWS, 'false');
    }

    checkPlanExpiry(plan);
  }, [announcement, route, setTitle, updateNewsAtt, user.email, plan]);

  return (
    <HeaderContainer>
      <Container>
        {/* <BannerContent
          src={BFBanner}
          onClick={() => {
            window.open('/pagamentos');
          }}
        /> */}
        <DataHomeResume />
        {loadingActive || strategyPercentageLoading ? (
          <LoadingBanner />
        ) : (
          <RowDiv isShort={shortSidebar}>
            <PortfolioBalanceSheetStatistics
              percentage={percentage}
              strategyPercentage={strategyPercentage}
            />
            <CarouselDashInfos />
          </RowDiv>
        )}

        <div className="containerTutorial" style={{ width: '100%' }}>
          <Heading
            as="h3"
            variant="tertiary"
            className="tutorial"
            color="#535862"
          >
            Tutorial
          </Heading>
          <div style={{ position: 'relative', paddingTop: '46.25%' }}>
            <iframe
              id="panda-df4c57bf-9d44-49bd-80dd-cc14c4b91576"
              src="https://player-vz-447732c4-8c0.tv.pandavideo.com.br/embed/?v=df4c57bf-9d44-49bd-80dd-cc14c4b91576"
              style={{
                border: 'none',
                borderRadius: 16,
                position: 'absolute',
                top: 0,
                left: 0,
              }}
              allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture"
              allowFullScreen
              width="100%"
              height="100%"
            ></iframe>
          </div>
        </div>
        {/* <TutorialIbox /> */}
        <ModalDesigner
          isOpen={isPromotion}
          id="congratulations"
          title="📢 Atenção, iBoxer!"
          setState={(newState) => {
            // emptyCacheStorage();
            // window.location.reload();
            localStorage.setItem(process.env.REACT_APP_ANNOUNCEMENT, 'close');
            setIsPromotion(newState);
            // setNewAtt(newState);
          }}
        >
          <NewsComponent />
        </ModalDesigner>
        <ModalDesigner
          isOpen={modalWelcome}
          id="congratulations"
          setState={setModalWelcome}
        >
          <CongratulationsCreatingAccount />
        </ModalDesigner>
        <div id="avisoExpira">
          <ModalDesigner
            id="avisoModal"
            className="modal-designer"
            isOpen={modalVisible}
            setState={setModalVisible}
          >
            <h2 id="aviso">📢Sua assinatura está expirando!</h2>
            <h4 id="subtititulo">Olá, iBoxer!</h4>
            <p id="mensagem">{modalMessage}</p>
          </ModalDesigner>
        </div>
      </Container>
    </HeaderContainer>
  );
};
export default Home;
