import ReactApexChart from 'react-apexcharts';
import { Warning } from '@phosphor-icons/react';
import { Tooltip } from 'antd';
import { Brokerages } from '../../components/Brokerages/intex';
import { CardGraphic, StrategyContainer } from './styles';
import { useActives } from '../../../../../hooks/useActives';
import { useStrategy } from '../../hooks/StrategyHooks';
import { useEffect, useState } from 'react';

export function Strategy() {
  // @ts-ignore
  const { percentage } = useActives();
  const { action, checkIfItIsBalanced, fiis, rendaFixa } = useStrategy();
  const [chartWidth, setChartWidth] = useState(() => {
    if (window.innerWidth > 1900) {
      return 1400;
    }
    if (window.innerWidth > 1800) {
      return 1300;
    }
    if (window.innerWidth > 1700) {
      return 1150;
    }
    if (window.innerWidth > 1600) {
      return 1000;
    }
    if (window.innerWidth > 1400) {
      return 850;
    }
    if (window.innerWidth > 1350) {
      return 750;
    }
    if (window.innerWidth > 1300) {
      return 650;
    }
    if (window.innerWidth > 1250) {
      return 600;
    }
    if (window.innerWidth > 1200) {
      return 500;
    }
    if (window.innerWidth <= 510) {
      return window.innerWidth * 0.7;
    }
    if (window.innerWidth <= 630) {
      return window.innerWidth * 0.65;
    }
    if (window.innerWidth <= 768) {
      return window.innerWidth * 0.7;
    }
    return window.innerWidth * 0.8 - 370;
  }); // Defina a largura inicial do gráfico

  useEffect(() => {
    // Função para atualizar a largura do gráfico quando a janela for redimensionada
    const handleResize = () => {
      setChartWidth(
        window.innerWidth > 1900
          ? 1400
          : window.innerWidth > 1800
          ? 1300
          : window.innerWidth > 1700
          ? 1150
          : window.innerWidth > 1600
          ? 1000
          : window.innerWidth > 1400  //110%
          ? 850
          : window.innerWidth > 1350 //100%
          ? 750
          : window.innerWidth > 1300 //90%
          ? 650
          : window.innerWidth > 1250 //80%
          ? 600
          : window.innerWidth > 1200 //80%
          ? 500
          : window.innerWidth <= 510
          ? window.innerWidth * 0.5
          : window.innerWidth <= 630
          ? window.innerWidth * 0.6
          : window.innerWidth <= 768
          ? window.innerWidth * 0.7
          : window.innerWidth * 0.8 - 370
      ); // Atualize a largura com base na largura da janela
    };

    // Adicione um ouvinte de redimensionamento para atualizar dinamicamente a largura do gráfico
    window.addEventListener('resize', handleResize);

    // Remova o ouvinte de redimensionamento ao desmontar o componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let compare = {
    series: [
      {
        name: 'Ideal',
        data: [
          action ? action.toFixed(2) : 0,
          fiis ? fiis.toFixed(2) : 0,
          rendaFixa ? rendaFixa.toFixed(2) : 0,
        ],
      },
      {
        name: 'Atual',
        data: [percentage.action, percentage.fii, percentage.rendaFixa],
      },
    ],
    options: {
      labels: ['Ações', 'Fiis', 'Renda Fixa'],

      chart: {
        type: 'bar',
      },

      fill: {
        opacity: 1,
      },
      dataLabels: {
        style: {
          colors: ['#ECF0F3'],
          fontSize: '16px',
        },

        formatter: (val: number) => {
          return `${val}%`;
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: ['#EC6643'],
            fontSize: '16px',
          },
          formatter: (val: number) => {
            return `${val}%`;
          },
        },
      },
      tooltip: {
        enabled: false,
        y: {
          formatter: (val: number) => {
            return `${val}%`;
          },
        },
      },
      grid: {
        borderColor: '#111',
        strokeDashArray: 0,
      },
      colors: ['#303F4B', '#F8B700'],
      xaxis: {
        labels: {
          style: {
            colors: ['#253A4A', '#253A4A', '#253A4A'],
            fontSize: '16px',
          },
        },
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'right',
        fontSize: '16px',
      },
    },
  };

  return (
    <StrategyContainer>
      {window.screen.width > 768 && <Brokerages />}
      <CardGraphic balanced={checkIfItIsBalanced}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <h2>Estratégia X Carteira</h2>
          {!checkIfItIsBalanced && (
            <Tooltip
              placement="bottom"
              title={
                <p style={{ margin: 0 }}>
                  Este gráfico demonstra a comparação entre sua estratégia e a
                  sua alocação de ativos,para adequação da sua carteira conforme
                  sua estratégia, efetue o balanceamento na aba de simulação de
                  aportes.
                </p>
              }
            >
              <Warning size={30} color="red" />
            </Tooltip>
          )}
        </div>

        <ReactApexChart
          // @ts-ignore
          options={compare.options}
          series={compare.series}
          type="bar"
          height={352}
          width={chartWidth}
        />
      </CardGraphic>
    </StrategyContainer>
  );
}
