import axios from 'axios';
import { useForm } from 'react-hook-form';
import MaskedInput from 'react-text-mask';
import { useEffect, useRef } from 'react';
import { useTitleAndUser } from '../../../../../../../hooks/userTitle';
import { handleToast } from '../../../../../../../components/Toast';
import { baseURL } from '../../../../../../../utils/baseURL';
import { userRoutes } from '../../../../../../../utils/RoutesApi';
import { ButtonDesign } from '../../../../../../../components/ButtonDesign';
import { ContentForm, ContentImg, ContentInput } from './styles';
import userDefaultImg from '../../../../../../../images/profile/User2.png';
import { Pencil } from '@phosphor-icons/react';

export function EditUserInfoForm() {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { isSubmitting },
  } = useForm();
  // @ts-ignore
  const { user, setUser } = useTitleAndUser();
  // @ts-ignore
  const getDataUser = JSON.parse(
    // @ts-ignore
    localStorage.getItem(process.env.REACT_APP_USER_VERSION)
  );
  const imageRef = useRef();

  useEffect(() => {
    if (user) {
      setValue('Name', user.Name);
      setValue('telefone', user.ddd + user.telefone);
      setValue('email', user.email);
    }
  }, []);

  function handleValidadeProfile(_data: any) {
    if (!_data.telefone) {
      return handleToast(true, 'Informe o telefone');
    }
    if (!_data.email) {
      return handleToast(true, 'Informe o email');
    }
    if (!_data.Name) {
      return handleToast(true, 'Informe o nome');
    }
  }

  // @ts-ignore
  async function submitProfile(data) {
    if (handleValidadeProfile(data)) {
      return;
    }
    data.telefone = data.telefone.replace(/\D/g, '');
    data.ddd = data.telefone.toString().substring(0, 2);
    data.telefone = data.telefone.toString().substring(2);

    await axios
      .patch(`${baseURL}${userRoutes.USER_UPDATE_PROFILE}`, data)
      .then((response) => {
        handleToast(false, 'Atualizado com sucesso');
        let res = {
          ...response.data,
          data_trial: getDataUser.dias_trial,
          step: 3,
        };
        if (res) {
          let isIncludeImage = false;
          if (res.profile.image) {
            const includeImage = res.profile.image.match(
              'https://storage.googleapis.com/ibox6-wordpress.appspot.com/'
            );

            isIncludeImage = !!includeImage;
          }

          let isIncludeBanner = false;

          if (res.profile.bannet) {
            const includeBanner = res.profile.banner.match(
              'https://storage.googleapis.com/ibox6-wordpress.appspot.com/'
            );

            isIncludeBanner = !!includeBanner;
          }

          if (isIncludeImage || isIncludeBanner) {
            let obj = {
              ...getDataUser,

              user: res,
            };
            localStorage.setItem(
              // @ts-ignore
              process.env.REACT_APP_USER_VERSION,
              JSON.stringify(obj)
            );
            setUser(obj.user);

            return;
          }

          if (res.profile.image) {
            res.profile.image =
              process.env.REACT_APP_API_URL +
              res.profile.image.replace(`${process.env.REACT_APP_API_URL}`, '');
          }
          if (res.profile.banner) {
            res.profile.banner =
              process.env.REACT_APP_API_URL +
              res.profile.banner.replace(
                `${process.env.REACT_APP_API_URL}`,
                ''
              );
          }

          let obj = {
            ...getDataUser,

            user: res,
          };
          localStorage.setItem(
            // @ts-ignore
            process.env.REACT_APP_USER_VERSION,
            JSON.stringify(obj)
          );
          setUser(obj.user);

          return;
        }
      })
      .catch((err) => {
        // return handleToast(true, err.response.data.response);
      });
  }

  const handleEditAvatar = () => {
    // @ts-ignore
    imageRef.current.click();
  };

  const handleChangeImageProfile = (event: any) => {
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append('image', image, image.name);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}${userRoutes.USER_UPDATE_IMAGE}`,
        formData
      )
      .then((response) => {
        let res = response.data;
        if (res) {
          const includeImage =
            res.image &&
            res.image.match(
              'https://storage.googleapis.com/ibox6-wordpress.appspot.com/'
            );

          const isIncludeImage = !!includeImage;

          const includeBanner =
            res.banner &&
            res.banner.match(
              'https://storage.googleapis.com/ibox6-wordpress.appspot.com/'
            );

          const isIncludeBanner = !!includeBanner;

          if (isIncludeImage) {
            let obj = getDataUser;
            obj.user.profile = res;
            localStorage.setItem(
              // @ts-ignore
              process.env.REACT_APP_USER_VERSION,
              JSON.stringify(obj)
            );
            setUser(obj.user);
          }

          if (isIncludeBanner) {
            let obj = getDataUser;
            obj.user.profile = res;
            localStorage.setItem(
              // @ts-ignore
              process.env.REACT_APP_USER_VERSION,
              JSON.stringify(obj)
            );
            setUser(obj.user);
          }

          if (isIncludeBanner || isIncludeImage) {
            return;
          }

          let obj = getDataUser;
          obj.user.profile = res;

          localStorage.setItem(
            // @ts-ignore
            process.env.REACT_APP_USER_VERSION,
            JSON.stringify(obj)
          );
          setUser(obj.user);
        }
      })
      .catch((err) => {});
  };

  return (
    <ContentForm onSubmit={handleSubmit(submitProfile)}>
      <ContentImg>
        <img src={user.profile.image ? user.profile.image : userDefaultImg} />
        <input
          hidden
          onChange={(event) => handleChangeImageProfile(event)}
          // @ts-ignore
          ref={imageRef}
          type="file"
        />
        <button type="button" onClick={handleEditAvatar}>
          <span>Alterar imagem</span> <Pencil color='#EC6643' />
        </button>
      </ContentImg>
      <ContentInput>
        <label>Nome Completo</label> {/* mudanças planejadas aqui */}
        <input type="text" {...register('Name')} />
      </ContentInput>
      <ContentInput>
        <label>Telefone</label>
        <MaskedInput
          mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d?/]}
          type="text"
          {...register('telefone')}
          render={(ref: React.Ref<HTMLInputElement>, props: any) => (
            <input {...props} ref={ref} />
          )}
        />
      </ContentInput>
      <ContentInput>
        <label>Email</label>
        <input type="text" {...register('email')} disabled />
      </ContentInput>
      <ButtonDesign
        style={{ width: 'calc(100% - 2rem - 4px)', maxWidth: 'none', marginTop:'29%' }}
        disabled={isSubmitting}
      >
        Atualizar
      </ButtonDesign>
    </ContentForm>
  );
}
