import styled from 'styled-components';

export const BrokeragesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  span {
    font-size: 0.975rem;
    font-weight: 400;
    color: ${(props) => props.theme['gray-500']};
  }
  scrollbar-width: thin; /* Para navegadores Firefox */
  scrollbar-color: transparent transparent; /* Para navegadores Firefox */
  overflow: auto; /* Para navegadores WebKit (Chrome, Safari, Edge) */
  touch-action: pan-y;
  ::-webkit-scrollbar {
    display: none !important;
    width: 6px; /* Largura da barra de rolagem para navegadores WebKit */
  }
  ::-webkit-scrollbar-thumb {
    background-color: transparent; /* Cor do polegar (barra de rolagem ativa) */
  }

  ::-webkit-scrollbar-track {
    background-color: transparent; /* Cor da faixa (barra de rolagem inativa) */
  }
`;

export const BrokerageContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  overflow-x: auto;
  width: 100%;
`;

export const BrokerageItem = styled.button`
  all: unset;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  background-color:rgba(48, 63, 75, 0.2); //rgba(131, 196, 255, 0.2)
  border-radius: 8px;
  cursor: pointer;
  padding: 0.5rem;
  width: 9.687rem;
  span {
    font-size: 0.85rem;
    font-weight: 500;
    color: ${(props) => props.theme['gray-700']};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  img {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 5px;
  }
`;
