import { styled } from 'styled-components';

export const AsideContentFilters = styled.aside`
  display: flex;
  max-width: 976px;
  width: 100%;
  height: 120px;
  padding: 16px 24px;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: var(--Spacing-8, 8px);
  border: 1px solid var(--Gray-gray-100, #D4D6DB);
  background: var(--Main-white, #FFF);
  margin-top: -2%;
  margin-left: 4%;
  position: relative;
  z-index: 3;
  span{
    color: var(--Gray-gray-800, #40444C);

    /* Body/Large/SemiBold */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27px */
  }

  @media (max-width: 1100px) {
    margin-bottom: 5rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const ContentInputFilters = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  height: 56px;
  padding: 4px 4px 4px 12px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  gap: 0.5rem;
  margin-top: 2.5%;
  position: relative; /* Adicionado para posicionamento correto */
  

  label {
    color: var(--Gray-gray-600, #6A717E);
    position: absolute; /* Adicionado para posicionamento independente */
    top: 20px; /* Ajuste conforme necessário */
    left: 10px; /* Ajuste conforme necessário */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
  }
`;


export const ButtonShowMoreFilters = styled.button`
  all: unset;
  background: rgba(131, 196, 255, 0.3);
  border-radius: 38px;
  padding: 0.75rem 1.5rem;
  text-align: center;
  font-size: 1rem;
  color: ${(props) => props.theme['primary-800']};
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
`;
