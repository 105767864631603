import { styled } from 'styled-components';

export const AsideContentFilters = styled.aside`
  display: flex;
  max-width: 976px;
  width: 100%;
  height: 120px;
  padding: 16px 24px;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: var(--Spacing-8, 8px);
  border: 1px solid var(--Gray-gray-100, #D4D6DB);
  background: var(--Main-white, #FFF);
  margin-top: -2%;
  margin-left: 4%;
  position: relative;
  z-index: 1;

  .filtros{
    color: var(--Gray-gray-800, #40444C);

    /* Body/Large/SemiBold */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27px */
  }

  @media (max-width: 1100px) {
    margin-bottom: 5rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const ContentInputFilters = styled.div`
  display: flex;
  flex-direction: column;
  background: transparent;
  height: 56px;
  padding: 4px 4px 4px 12px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  gap: 0.5rem;
  margin-top: 3%;
  position: relative; /* Adicionado para posicionamento correto */

  label {
    color: var(--Gray-gray-600, #6A717E);
    position: absolute; /* Adicionado para posicionamento independente */
    top: 0px; /* Ajuste conforme necessário */
    left: 65px; /* Ajuste conforme necessário */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
  }
  
 

  span {
    text-align: center;
    font-weight: 500;
    color: ${(props) => props.theme['gray-500']};
    font-size: 0.75rem;
  }

  select {
    border-color: transparent;
    margin-top: 0%;
    margin-left: 0%;
    display: flex;
    width: 250px;
    height: 56px;
    padding: 4px 4px 4px 12px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    border-bottom: 1px solid var(--Gray-gray-600, #6A717E);
    background: transparent;
    border-radius: 0;
    position: relative;
    font-size: 16px;
    color: inherit; /* Ajustado para herdar a cor do tema */

    &::placeholder {
      color: #808080;
    }

    option {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 8px;
      padding-bottom: 8px;
    }

    &::-ms-expand {
      display: none;
    }

    &:focus {
      outline: none;
      border-color: none;
    }
  }
`;


export const ButtonShowMoreFilters = styled.button`
  all: unset;
  background: rgba(131, 196, 255, 0.3);
  border-radius: 38px;
  padding: 0.75rem 1.5rem;
  text-align: center;
  font-size: 1rem;
  color: ${(props) => props.theme['primary-800']};
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
`;
