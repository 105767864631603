import styled from 'styled-components';

export const ContainerJurosCompostos = styled.main`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  gap: 1.5rem;
  background: ${(props) => props.theme.white};
  width: 100%;
  padding: 0 2.25rem 2rem;
  border-left: 1px solid var(--Gray-gray-100, #D4D6DB);
  border-right: 1px solid var(--Gray-gray-100, #D4D6DB);
  border-bottom: 1px solid var(--Gray-gray-100, #D4D6DB);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  @media (max-width: 720px) {
    grid-template-columns: 1fr;
  }
`;

export const ContentSimulador = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
  flex: 1 0 0;
  margin-top: 5%;
  
  border: 1px solid var(--Gray-gray-300, #A3A7B1);
  
  padding: 24px 24px 24px 24px;
  border-radius: 16px;
  opacity: 0px;
  
  width: 480px;
  height: 560px;
  

  label {
    color: #6A717E;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
  }
`;

export const InputContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Spacing-8, 8px);
  align-self: stretch;

  justify-content: space-between;
  input {
    display: flex;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    
    all: unset;
    font-weight: 400;
    color: #6A717E;
    font-size: 18px;
    width: 100%;
  }
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
`;
export const MonthlyInputContent = styled.div`
  display: flex;

  align-items: center;
  border-bottom: 1px solid #d3d3d7;

  input {
    margin-top: -5%;
    all: unset;
    font-weight: 400;
    color: ${(props) => props.theme['gray-n-600']};
    font-size: 18px;
    width: 300px;
    height: 47px;

    display: flex;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
  }
  span {
    color: #6A717E;

    /* Body/Large/Regular */
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px; /* 27px */
  }
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
`;

export const ContentInputPreFixed = styled.div`
  display: flex;
  align-items: center;

  
  width: 100%;

  span {
    font-weight: 400;
    color: ${(props) => props.theme['gray-n-500']};
    font-size: 18px;
    padding-right: 0.5rem;
  }
`;

export const ContentButtonValue = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  justify-content: flex-end;
  button {
    all: unset;
    border-radius: 100%;
    color: ${(props) => props.theme.white};
    background-color: ${(props) => props.theme['primary-800']};
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ContentFilterDateAndTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  select {
    padding: 0.5rem;
    color: #6A717E;
    font-size: 14px;
    width: 220px;
    height: 47px;
    gap: 4px;
    opacity: 0px;
    max-width: 7rem;
    position: relative;
    border-radius: 8px;
    border: 1px solid #A3A7B1;
  }
`;

export const ContentDataAndGraphic = styled.div`
  display: flex;
  margin-top: 5%;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px; /*24px*/
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;  

  h4 {
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 27px */
    width: 100%;
    border-bottom: 1px solid #A3A7B1;
  }

  button {
    all: unset;
    border-radius: 100%;
    padding: 0;
    background-color: #EC6643;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }

  span {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    
    color:#6A717E; 
    margin: 0;
  }


  p {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: var(--Spacing-8, 8px);

    align-self: stretch;

    /* Body/Base/Regular */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */

    margin: 0;
    width: 100%;

    strong {
      /* Body/Base/Regular */
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }
  }

  .apexcharts-canvas {
    margin-left: -1rem;
  }
`;

interface IPropsCalcButton {
  isOutline?: boolean;
}
export const CalcButton = styled.button<IPropsCalcButton>`
  all: unset;
  color: ${(props) => props.theme['white']};
  background: #EC6643;
  border-radius: 8px;
  padding: 1rem;
  width: 100%;
  height: 20px;
  text-align: center;
  font-weight: 700;
  font-size: 1rem;
  margin-top:50px;
`;
