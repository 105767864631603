import { styled } from 'styled-components';

export const AsideContentFilters = styled.aside`
  display: flex;
  max-width: 976px;
  width: 100%;
  height: 188px;
  padding: 16px 24px;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: var(--Spacing-8, 8px);
  border: 1px solid var(--Gray-gray-100, #D4D6DB);
  background: var(--Main-white, #FFF);
  margin-top: 2%;
  margin-left: 2%;
  position: relative;
  z-index: 2;

  @media (max-width: 1100px) {
    margin-bottom: 5rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const AsideHeader = styled.div`
  display: flex;
  max-width: 946px;
  padding: 16px 24px;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  margin-left: -2%;
  margin-top: -1%;

  span {
    width: 100%;
    max-width: 18.75rem;
    color: ${(props) => props.theme['gray-400']};
    font-weight: 500;
    font-size: 1rem;
  }
`;

export const FiltersStyles = styled.div`
  display: flex;
  flex-direction: row; /* Padrão */
  justify-content: flex-start;
  align-items: left;
  gap: 24px;
  margin-top: 0;
  margin-left: -24%;
`;

export const ContentInputFilters = styled.div`
  display: flex;
  flex-direction: column;
  background: transparent;
  height: 56px;
  padding: 4px 4px 4px 12px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  gap: 0.5rem;
  margin-top: 10%;

  
  label {
    color: var(--Gray-gray-600, #6A717E);
    margin-top: 0;
    margin-bottom: -12%;
    margin-left: 0;
    position: relative;
    /* Body/XSmall/Regular */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
  }
  span {
    text-align: center;
    font-weight: 500;
    color: ${(props) => props.theme['gray-500']};
    font-size: 0.75rem;
  }
`;

export const ContentInputShowFilters = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 108px;
  margin-right: 10px;
  padding: 8px 8px 8px 24px;
  flex-wrap: wrap;

  label {
    text-align: center;
    color: var(--Gray-gray-600, #6A717E);
    /* Body/XSmall/Regular */
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
  }
    span {
    text-align: center;
    font-weight: 500;
    color: ${(props) => props.theme['gray-500']};
    font-size: 0.57rem;
  }
`;

export const ShowMoreFilters = styled.div`
  display: grid;
  flex-direction: row; /* Padrão */
  gap: 24px;
  padding: 8px 8px 8px 8px;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
`;

export const ContentInputShowFilters2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 285px;
  height: 108px;
  margin-right: 10px;
  padding: 8px 8px 8px 24px;
  flex-wrap: wrap;

  label {
    text-align: center;
    color: var(--Gray-gray-600, #6A717E);
    /* Body/XSmall/Regular */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
  }
    span {
    text-align: center;
    font-weight: 500;
    color: ${(props) => props.theme['gray-500']};
    font-size: 0.57rem;
  }
`;

export const ButtonShowMoreFilters = styled.button`
  display: flex;
  padding: var(--Spacing-8, 8px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  all: unset;
  background: #fff;
  border-radius: 8px;
  color: #6A717E;
`;
