import React from 'react';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const HeaderContainer2 = styled.div`
  width: 100%;
  max-width: 1116px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;
  overflow: hidden;
`;

// const Header = ({ children }: React.PropsWithChildren<{}>) => {
//   return <HeaderContainer>{children}</HeaderContainer>;
// };
