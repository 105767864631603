import { Progress, Tooltip } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from 'react-bootstrap';
import {
  CaretDown,
  CaretUp,
  ChartLineUp,
  Eye,
  EyeSlash,
  Scales,
} from '@phosphor-icons/react';

import { useTitleAndUser } from '../../../../../hooks/userTitle';
import { financeRoutes } from '../../../../../utils/RoutesApi';
import { Col, Container, ContainerBrokerages, ContentTab, Row } from './styles';
import { CardCorretora } from '../../../CardCorretora';
import { filterTableSorte } from '../../../../../utils/filterTableSort';
import { GraphicWalletActives } from '../Graphic';
import { FormAporte } from '../FormAporte';
import { FormCreateRule } from '../FormCreateRule';
import {
  IPropsPosition,
  IWalletProps,
} from '../../../../../utils/@types/wallet';
import { useActives } from '../../../../../hooks/useActives';
import { RenderConditionalObjective } from '../../../RenderConditionalObjective';
import { defineDisplayColor } from '../../DefineDisplayColor';
import {
  ContentItemsFilterColumnTable,
  ContentTabCards,
} from '../MinhaCarteiraStyles';
import { TableLoader } from '../../../LoadingModels';
import { transformMaskCurrency } from '../../../../../utils/transformMaskCurrency';
import { ButtonComponent } from '../../../../../components/ButtonComponent';
import { TabComponent } from '../../../../../components/TabComponent';
import { ModalStyled } from '../../../Dashboard/components/TutorialIbox/styles';
import { ModalDesigner } from '../../../../../components/ModalDesigner';

interface IPropsBrokerage {
  corretora: string;
  sumcorretora: number;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#FFF',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const header = [
  { label: 'Ativo', active: 'tickerSymbol' },
  { label: 'Nota', active: 'nota' },
  { label: 'Quant.', active: 'equitiesQuantity' },
  { label: 'Patrimônio', active: 'updateValue' },
  { label: 'Cotação', active: 'closingPrice' },
  { label: 'Objetivo', active: 'ValorExp' },
  { label: 'Atual', active: 'percent' },
  { label: 'Diferença', active: 'gap' },
  { label: 'Preço médio', active: 'PrecoMedio' },
  { label: 'Rentabilidade', active: 'Rentabilidade' },
  { label: 'Lucro/Prejuízo', active: 'lucro' },
];

export function WalletFii() {
  const filterTable = localStorage.getItem('filterTableFII-v1.0.0');
  const filterTableJson: string[] = filterTable ? JSON.parse(filterTable) : [];
  const [showBrokerages, setShowBrokerages] = useState(false);
  const history = useHistory();
  //@ts-ignore
  const { showActive } = useActives();
  //@ts-ignore
  const { setTitle, user } = useTitleAndUser();
  const [updateValue, setUpdateValue] = useState<number>(0);
  const [tab, setTab] = useState(1);
  const [filterBrokerage, setFilterBrokerage] = useState('');
  const [showAmountActives, setShowAmountActives] = useState(false);
  const [showActiveName, setShowActiveName] = useState(false);
  const [loadingWallet, setLoadingWallet] = useState(false);

  const [walletValues, setWalletValues] = useState<IWalletProps>({
    novoSumAcao: 0,
    novoSumFII: 0,
    positionAcao: [],
    positionFII: [],
    positionsAll: [],
    sumAcao: 0,
    sumsEFT: 0,
    sumsFII: 0,
    automatico: false,
    objetivoFII: 0,
    objetivoAcao: 0,
    rentabilidade: [],
    objetivoAll: 0,
    objetivoAllAcao: 0,
    objetivoAllFII: 0,
    objetivoAllRenda: 0,
  });
  const [brokerages, setBrokerages] = useState<IPropsBrokerage[]>([]);
  const [showGraphic, setShowGraphic] = useState<boolean>(false);
  const [showModalCreateRule, setShowModalCreateRule] = useState(false);
  const [rule, setRule] = useState({});
  const [othersFii, setOthersFii] = useState([]);
  const [showModalFilterColumnTable, setShowModalFilterColumnTable] =
    useState(false);
  const [filterShowColumnTable, setFilterShowColumnTable] = useState<string[]>(
    () => {
      if (filterTableJson.length <= 0) {
        return [
          'Ativo',
          'Tipo',
          'Nota',
          'Quant.',
          'Patrimônio',
          'Objetivo',
          'Atual',
          'Diferença',
        ];
      } else {
        return filterTableJson;
      }
    }
  );
  const arrColumnTableData = [
    'Ativo',
    'Cotação',
    'Nota',
    'Quant.',
    'Patrimônio',
    'Objetivo',
    'Atual',
    'Diferença',
    'Lucro/Prejuízo',
    'Rentabilidade',
    'Preço médio',
  ];

  useEffect(() => {
    setShowBrokerages(false);
    setShowGraphic(false);
  }, [tab]);

  useEffect(() => {
    if (filterBrokerage !== '') {
      componentDidMount({ corretora: filterBrokerage });
    } else {
      componentDidMount();
    }
  }, [user.automatico, filterBrokerage]);

  useEffect(() => {
    setTitle('FIIs');
    handleGetBrokerages();
  }, [user.automatico]);
  useEffect(() => {
    localStorage.setItem(
      'filterTableFII-v1.0.0',
      JSON.stringify(filterShowColumnTable)
    );
  }, [filterShowColumnTable]);

  const showActiveColumnTable = filterShowColumnTable.includes('Ativo');
  const showNoteColumnTable = filterShowColumnTable.includes('Nota');
  const showAmountColumnTable = filterShowColumnTable.includes('Quant.');
  const showPatrimonyColumnTable = filterShowColumnTable.includes('Patrimônio');
  const showGoalColumnTable = filterShowColumnTable.includes('Objetivo');
  const showPriceColumnTable = filterShowColumnTable.includes('Cotação');
  const showCurrentColumnTable = filterShowColumnTable.includes('Atual');
  const showGapColumnTable = filterShowColumnTable.includes('Diferença');

  const showProfitColumnTable =
    filterShowColumnTable.includes('Lucro/Prejuízo');
  const showProfitabilityColumnTable =
    filterShowColumnTable.includes('Rentabilidade');
  const showAveragePriceColumnTable =
    filterShowColumnTable.includes('Preço médio');

  async function componentDidMount(params: {} = {}) {
    setLoadingWallet(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_POSITION}`,
        { params }
      )
      .then((result) => {
        let totalUpdateValue = 0;

        result.data.positionFII.map((item: IPropsPosition) => {
          totalUpdateValue = item.updateValue + totalUpdateValue;
        });

        let totalFii = [];
        if (result.data.positionFII.length > 0) {
          totalFii = result.data.positionFII.filter(
            (item: IPropsPosition) => !item.reservadeemergencia
          );
          totalFii = totalFii.map((item: IPropsPosition) => {
            return item.updateValue;
          });

          //@ts-ignore
          totalFii = totalFii.reduce((acc, value) => acc + value);
        }

        setUpdateValue((totalFii ?? 0) as any);

        let newDataArrayFii = result.data.positionFII.map(
          (item: IPropsPosition) => {
            const custo =
              Number(item.PrecoMedio.toFixed(2)) * item.equitiesQuantity;
            item.lucro = Number(item.updateValue.toFixed(2)) - custo;
            if (!item.Exception) {
              item.ValorExp = result.data.objetivoFII;
              item.gap = item.ValorExp - item.percent;

              return item;
            }
            item.gap = item.ValorExp - item.percent;

            return item;
          }
        );
        newDataArrayFii = newDataArrayFii.filter(
          (item: IPropsPosition) => !item.reservadeemergencia
        );

        setWalletValues({
          ...result.data,

          positionFII: newDataArrayFii,
        });

        if (result.data.positionFII.length > 0) {
          let fiis = result.data.positionFII.filter(
            (item: IPropsPosition) => !item.reservadeemergencia
          );
          fiis = fiis.filter((item: IPropsPosition) => item.percent > 0.0);

          fiis = fiis.filter((item: IPropsPosition) => item.percent < 1);

          fiis = fiis.map((item: IPropsPosition) => item.percent);

          let largerThanOne = result.data.positionFII.filter(
            (item: IPropsPosition) => !item.reservadeemergencia
          );

          largerThanOne = largerThanOne.filter(
            (item: IPropsPosition) => item.percent >= 1
          );
          if (fiis.length > 0) {
            //@ts-ignore
            fiis = fiis.reduce((a, b) => a + b);

            fiis = [
              ...largerThanOne,
              {
                percent: fiis,
                tickerSymbol: 'Outros',
                Exception: false,
                ValorExp: 0,
                aportarQtde: 0,
                closingPrice: 0,
                corporationName: '',
                equitiesQuantity: 0,
                novoupdateValue: 0,
                updateValue: 0,
              },
            ];

            setOthersFii(fiis);
          } else {
            setOthersFii(largerThanOne);
          }
        }
      });
    setLoadingWallet(false);
  }
  function handleGetBrokerages() {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_BROKERAGES}`,
        {
          params: {
            tipo_ativo: 'fii',
          },
        }
      )
      .then(function (result) {
        let newResult = result.data.result.filter(
          (item: { corretora: string; sumcorretora: number }) =>
            item.corretora !== ''
        );
        newResult = newResult.filter(
          (item: { corretora: string; sumcorretora: number }) =>
            item.sumcorretora > 0
        );

        setBrokerages(newResult);
      });
  }

  const totalValueInTable = transformMaskCurrency(updateValue);

  const totalValueBrokerages = brokerages.reduce(
    (acc, value) => acc + value.sumcorretora,
    0
  );
  function handleSelectedColumnTable(value: string) {
    const isValueAllReadyExist = filterShowColumnTable.find(
      (item: string) => item === value
    );
    if (isValueAllReadyExist) {
      setFilterShowColumnTable((state) => {
        return state.filter((item: string) => item !== value);
      });
    } else {
      setFilterShowColumnTable((state) => {
        return [...state, value];
      });
    }
  }

  return (
    <>
      <Container wallet={brokerages.length > 0}>
        <ContentTabCards>
          <TabComponent
            title="Ativos"
            icon={<ChartLineUp />}
            active={tab === 1}
            onClick={() => setTab(1)}
          />

          <TabComponent
            title="Simular aporte"
            icon={<Scales />}
            onClick={() => setTab(3)}
            active={tab === 3}
          />
        </ContentTabCards>
        <ContentTab tab={tab}>
          <ButtonComponent
            variant="link"
            onClick={() => setShowBrokerages(!showBrokerages)}
          >
            {showBrokerages ? 'Esconder corretoras' : 'Exibir corretoras'}
          </ButtonComponent>
          <ButtonComponent
            variant="link"
            onClick={() => setShowGraphic(!showGraphic)}
          >
            Visualizar gráficos
          </ButtonComponent>
        </ContentTab>
        {brokerages.length > 0 && (
          <ContainerBrokerages show={showBrokerages}>
            {brokerages.length > 1 && (
              <CardCorretora
                nameBroken="Todas as corretoras"
                patrimony={Number(totalValueBrokerages.toFixed(2))}
                onClick={() => setFilterBrokerage('')}
                active={filterBrokerage === ''}
              />
            )}
            {brokerages.map((item) => (
              <CardCorretora
                key={item.corretora}
                onClick={() =>
                  brokerages.length > 1 && setFilterBrokerage(item.corretora)
                }
                nameBroken={item.corretora}
                patrimony={item.sumcorretora}
                active={filterBrokerage === item.corretora}
              />
            ))}
          </ContainerBrokerages>
        )}

        {tab === 1 && (
          <Col>
            {loadingWallet ? (
              <TableLoader />
            ) : (
              <Row>
                <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
                  <div
                    style={{
                      padding: '0.5rem',
                      display: 'flex',
                    }}
                  >
                    <ButtonComponent
                      type="button"
                      onClick={() => setShowModalFilterColumnTable(true)}
                    >
                      Escolher colunas da tabela
                    </ButtonComponent>
                  </div>
                  <Table
                    sx={
                      window.screen.width <= 480
                        ? { minWidth: 300 }
                        : { minWidth: 700 }
                    }
                    stickyHeader
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        {header.map((item) => {
                          if (filterShowColumnTable.includes(item.label)) {
                            return (
                              <StyledTableCell key={item.label}>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                    fontSize: '0.95rem',
                                  }}
                                >
                                  {item.label}
                                  {item.active && (
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                      }}
                                    >
                                      <CaretUp
                                        size={14}
                                        color="black"
                                        onClick={() => {
                                          setWalletValues({
                                            ...walletValues,
                                            //@ts-ignore
                                            positionFII: filterTableSorte({
                                              data: walletValues.positionFII,
                                              dir: 'asc',
                                              //@ts-ignore
                                              key: item.active,
                                            }),
                                          });
                                        }}
                                      />
                                      <CaretDown
                                        size={14}
                                        color="black"
                                        onClick={() => {
                                          setWalletValues({
                                            ...walletValues,
                                            //@ts-ignore
                                            positionFII: filterTableSorte({
                                              data: walletValues.positionFII,
                                              dir: 'desc',
                                              //@ts-ignore
                                              key: item.active,
                                            }),
                                          });
                                        }}
                                      />
                                    </div>
                                  )}
                                  {item.label === 'Quant.' &&
                                    (showAmountActives ? (
                                      <Eye
                                        size={20}
                                        color="black"
                                        onClick={() =>
                                          setShowAmountActives(
                                            !showAmountActives
                                          )
                                        }
                                      />
                                    ) : (
                                      <EyeSlash
                                        size={20}
                                        color="black"
                                        onClick={() =>
                                          setShowAmountActives(
                                            !showAmountActives
                                          )
                                        }
                                      />
                                    ))}
                                  {item.label === 'Ativo' &&
                                    (showActiveName ? (
                                      <Eye
                                        size={20}
                                        color="black"
                                        onClick={() =>
                                          setShowActiveName(!showActiveName)
                                        }
                                      />
                                    ) : (
                                      <EyeSlash
                                        size={20}
                                        color="black"
                                        onClick={() =>
                                          setShowActiveName(!showActiveName)
                                        }
                                      />
                                    ))}
                                </div>
                              </StyledTableCell>
                            );
                          }
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {walletValues.positionFII.length > 0 ? (
                        walletValues.positionFII.map((item, index) => (
                          <StyledTableRow key={index}>
                            {showActiveColumnTable && (
                              <StyledTableCell
                                className="reduce link"
                                onClick={() =>
                                  history.push(`/fii/${item.tickerSymbol}`)
                                }
                              >
                                {showActiveName || showActive ? (
                                  <>
                                    <span>{item.tickerSymbol}</span>
                                    <br />
                                    <strong title={item.corporationName}>
                                      {' '}
                                      {item.corporationName}
                                    </strong>
                                  </>
                                ) : (
                                  '*****'
                                )}
                              </StyledTableCell>
                            )}
                            {showNoteColumnTable && (
                              <StyledTableCell style={{ width: '150px' }}>
                                <Tooltip
                                  title={
                                    item.nota <= 0
                                      ? 'Nota não aplicável a esse ativo ou não encontrada'
                                      : `${item.nota.toFixed(0)}%`
                                  }
                                >
                                  {item.nota <= 0 ? (
                                    <>N/A</>
                                  ) : (
                                    <Progress
                                      percent={item.nota}
                                      strokeColor={
                                        item.nota <= 50
                                          ? 'red'
                                          : item.nota <= 60
                                          ? 'yellow'
                                          : 'var(--primary)'
                                      }
                                      showInfo={false}
                                      trailColor="rgba(21,21,21,0.2)"
                                    />
                                  )}
                                </Tooltip>
                              </StyledTableCell>
                            )}
                            {showAmountColumnTable && (
                              <StyledTableCell>
                                {showActive || showAmountActives
                                  ? item.equitiesQuantity
                                  : '****'}
                              </StyledTableCell>
                            )}
                            {showPatrimonyColumnTable && (
                              <StyledTableCell
                                className="reduce"
                                title={
                                  showActive
                                    ? transformMaskCurrency(item.updateValue)
                                    : 'R$ ******'
                                }
                              >
                                {showActive
                                  ? transformMaskCurrency(item.updateValue)
                                  : 'R$ ******'}
                              </StyledTableCell>
                            )}
                            {showPriceColumnTable && (
                              <StyledTableCell>
                                {transformMaskCurrency(item.closingPrice)}
                              </StyledTableCell>
                            )}

                            {showGoalColumnTable && (
                              //@ts-ignore
                              <RenderConditionalObjective
                                active={item}
                                exceptions={item.Exception}
                                setRule={setRule}
                                setShowModalCreateRule={setShowModalCreateRule}
                                //@ts-ignore
                                walletValues={walletValues}
                                fii
                              />
                            )}
                            {showCurrentColumnTable && (
                              <StyledTableCell
                                style={{
                                  color: defineDisplayColor({
                                    item,
                                    exceptions: item.Exception,
                                    type: 'fii',
                                    //@ts-ignore
                                    wallet: walletValues,
                                    filterBrokerage: undefined,
                                  }),
                                }}
                              >
                                {item.percent.toFixed(2)}%
                              </StyledTableCell>
                            )}
                            {showGapColumnTable && (
                              <StyledTableCell>
                                {item?.gap?.toFixed(2)}%
                              </StyledTableCell>
                            )}
                            {showAveragePriceColumnTable && (
                              <StyledTableCell
                                className="reduce"
                                title={
                                  showActive
                                    ? transformMaskCurrency(item.PrecoMedio)
                                    : 'R$ ******'
                                }
                              >
                                {showActive
                                  ? transformMaskCurrency(item.PrecoMedio)
                                  : 'R$ ******'}
                              </StyledTableCell>
                            )}
                            {showProfitabilityColumnTable && (
                              <StyledTableCell
                                style={{
                                  color:
                                    item.Rentabilidade < 0 ? 'red' : '#44bd32',
                                }}
                              >
                                {item?.Rentabilidade?.toFixed(2)}%
                              </StyledTableCell>
                            )}

                            {showProfitColumnTable && (
                              <StyledTableCell
                                className="reduce"
                                title={
                                  showActive
                                    ? transformMaskCurrency(item.lucro)
                                    : 'R$ ******'
                                }
                                style={{
                                  color: item.lucro < 0 ? 'red' : '#44bd32',
                                }}
                              >
                                {showActive
                                  ? transformMaskCurrency(item.lucro)
                                  : 'R$ ******'}
                              </StyledTableCell>
                            )}
                          </StyledTableRow>
                        ))
                      ) : (
                        <h1 style={{ fontSize: '1rem', margin: '1rem 0.5rem' }}>
                          Ainda não há dados
                        </h1>
                      )}
                    </TableBody>
                  </Table>
                  <footer className="footer-table">
                    <b>Total</b>
                    <b>{showActive ? totalValueInTable : 'R$ ******'}</b>
                  </footer>
                </TableContainer>
                {showGraphic && (
                  <GraphicWalletActives
                    active="fii"
                    title="Distribuição por ativo"
                    othersFii={othersFii}
                  />
                )}
              </Row>
            )}
          </Col>
        )}

        {tab === 3 && (
          <FormAporte
            type="fii"
            //@ts-ignore
            wallet={walletValues}
            othersFii={othersFii}
            showGraphic={showGraphic}
            filterBroker={filterBrokerage}
          />
        )}
      </Container>
      {showModalCreateRule && (
        <FormCreateRule
          //@ts-ignore
          rule={rule}
          setShowModalCreateRule={setShowModalCreateRule}
          showModalCreateRule={showModalCreateRule}
          update={() => componentDidMount()}
        />
      )}

      <ModalDesigner
        title="Editar campos da tabela"
        isOpen={showModalFilterColumnTable}
        setState={setShowModalFilterColumnTable}
        id="editarTabela"
        size="sm"
      >
        <ContentItemsFilterColumnTable>
          {arrColumnTableData.map((item) => (
            <label key={item}>
              <input
                type="checkbox"
                value={item}
                defaultChecked={filterShowColumnTable.includes(item)}
                onChange={(e) => handleSelectedColumnTable(e.target.value)}
              />
              {item}
            </label>
          ))}
        </ContentItemsFilterColumnTable>
      </ModalDesigner>
    </>
  );
}
