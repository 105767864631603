import styled from "styled-components";

export const AsideFiltersProventosRecebidosContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  max-width: 916px;
  height: 132px;
  padding: 4px 24px 4px 4px;
  border: 1px solid var(--Gray-gray-100, #D4D6DB);
  border-radius: 8px;
  background: #fff;
  position: relative;
  z-index: 2;
  margin-left: 3rem;
  margin-top: 0;

  .filtros {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 12px;
    background: transparent;
    border-radius: var(--Spacing-8, 8px);
    border: 1px solid transparent;

    span {
      color: var(--Gray-gray-800, #40444C);
      font-family: Poppins;
      font-size: 18px;
      font-weight: 600;
      line-height: 150%; /* 27px */
      width: 100%;
    }
  }
`;

export const RowLines = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;
  height: 120px;
  margin-top: -10%;
  margin-left: 1.5rem;
`;

export const FiltersStyles = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Define 3 colunas */
  grid-template-rows: repeat(2, auto); /* Define 2 linhas */
  gap: 24px 24px; /* Espaçamento horizontal */
  row-gap: 0px; /* Espaçamento vertical */
  height: 115px;
  width: 100%;
  margin-top: 1%;
`;


export const ContentInputAno = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-top: 4.1rem;
  height: 120px;

  .radioContent {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    row-gap: 0px;
    align-items: center;
  }

  .radioContentLabel {
    color: rgb(78, 84, 95); /* #6A717E */
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 150%; /* 18px */
    white-space: nowrap;
  }

  .inputRadio {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const ContentInput = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
  height: 60px;
  position: relative;
  gap: 0px;
  row-gap: 0px;

  label {
    font-weight: 400;
    font-size: 0.875rem;
    margin-bottom: 2px; /* Ajuste o valor conforme necessário */
  }

  .deLabel {
    color: #6A717E;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 150%; /* 18px */
    margin-bottom: 2px; /* Ajuste o valor conforme necessário */
  }

  .inputDate {
    display: flex;
    height: 56px;
    width: 100%;
    gap: 0;
    row-gap: 0px;
    padding: 4px 12px;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-bottom: 1px solid #6A717E;
    background: transparent;
    font-weight: 400;
    font-size: 1rem;
  }

  .tipoLabel {
    color: #6A717E;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 150%; /* 18px */
    margin-bottom: 2px; /* Ajuste o valor conforme necessário */
  }

  .selectTipo {
    display: flex;
    height: 56px;
    width: 100%;
    row-gap: 0px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #6A717E;
    background: transparent;
    white-space: nowrap;
  }

  .ativoLabel {
    color: #6A717E;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 150%; /* 18px */
    margin-bottom: 2px; /* Ajuste o valor conforme necessário */
  }

  .selectAtivo {
    display: flex;
    height: 56px;
    width: 100%;
    row-gap: 0px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #6A717E;
    background: transparent;
    white-space: nowrap;
  }

  .corretoraLabel {
    color: #6A717E;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 150%; /* 18px */
    margin-bottom: 2px; /* Ajuste o valor conforme necessário */
    white-space: nowrap;
  }

  .selectCorretora {
    display: flex;
    height: 56px;
    width: 100%;
    row-gap: 0px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #6A717E;
    background: transparent;
    white-space: nowrap;
  }

  .proventosLabel {
    color: #6A717E;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 150%; /* 18px */
    margin-bottom: 2px; /* Ajuste o valor conforme necessário */
    white-space: nowrap;
  }

  .selectProventos {
    display: flex;
    height: 56px;
    width: 100%;
    row-gap: 0px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #6A717E;
    background: transparent;
    white-space: nowrap;
  }
`;
