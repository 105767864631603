import styled from 'styled-components';

export const FormEditJurosContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  p {
    font-size: 1.125rem;
    font-weight: 400;
    color: #6A717E;
    margin: 0;
    strong {
      font-weight: 600;
      background: ${(props) => props.theme['primary-800']};
      color: ${(props) => props.theme.white};
      border-radius: 32px;
      padding: 0.25rem 0.5rem;
    }
  }

  .contentValue {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    border-radius: 8px;
    border: 1px solid var(--Gray-gray-100, #D4D6DB);
    background: var(--Main-white, #FFF);
    h4 {
      font-size: 0.875rem;
      color: #436A86;
      font-weight: 500;
      padding-left: 8px;
      padding-top: 8px;
    }
    span {
      font-size: 1rem;
      font-weight: 500;
      color: #71717A;
      padding-left: 8px;
      padding-bottom: 8px;

      strong {
        color: ${(props) => props.theme['gray-700']};
      }
    }
  }
  .bottomLine{
    border-bottom: 1px solid var(--Gray-gray-100, #D4D6DB);
    background: var(--Main-white, #FFF);
    padding: 12px;
  }
`;
export const ContentInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  label {
    font-weight: 500;
    font-size: 1.125rem;
    color: ${(props) => props.theme['gray-600']};
  }

  select {
    border: none;
    border-left: 1px solid ${(props) => props.theme['gray-100']};
    padding-left: 0.5rem;
  }
`;
