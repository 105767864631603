import styled, { css } from 'styled-components';

interface IPropsContainerCardHomeData {
  isBlocked: boolean;
}

export const Container = styled.div<IPropsContainerCardHomeData>`
  display: flex;
  max-width: 384px;
  height: 132px; // 112px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Spacing-8, 8px);
  flex: 1 0 0;

  box-shadow: ${(props) => props.theme['card-shadow-2']};
  position: relative;
  border-radius: var(--Spacing-8, 8px);
  border: 1px solid var(--Gray-gray-100, #d4d6db);
  background: #fff;

  span {
    color: var(--Gray-gray-700, #535862);

    /* Body/Small/Medium */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%; /* 20.3px */
    margin-top: 0;
  }
  .imagem {
    position: absolute;
    margin-left: 80%;
  }
  h1 {
    align-self: stretch;
    color: var(--Gray-gray-800, #40444c);
    /* Heading/H5/SemiBold */
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
    margin-top: 0;
  }
  .blocked {
  position: absolute;
  width: 200px;
  height: 40px;
  background: rgba(255, 255, 255, 0.7);
  bottom: 10px;
  left: 20px;
  -webkit-filter: blur(8px); 
  -moz-filter: blur(8px); 
  -o-filter: blur(8px); 
  -ms-filter: blur(8px); 
  filter: blur(8px); 
  }
`;

export const Value = styled.span`
  font-size: 1.5rem; // Tamanho da fonte
  color: darkblue; // Cor do texto
  font-weight: bold; // Peso da fonte
  margin: 0.5rem 0; // Margem
`;
